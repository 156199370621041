import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProductDisplay from './components/ProductDisplay';
import SalesDashboard from './components/sales/SalesDashboard';
import AdminDashboard from './components/admin/AdminDashboard';
import AddUser from './components/admin/AddUser';
import AddProduct from './components/admin/AddProduct';
import AddClient from './components/admin/AddClient';
import ManageProducts from './components/admin/ManageProducts';
import ManageClients from './components/admin/ManageClients';
import ManageOrders from './components/admin/ManageOrders';
import ManageUsers from './components/admin/ManageUsers';
import Profile from './components/admin/Profile';
import SalesAddClient from './components/sales/AddClient';
import SalesManageClients from './components/sales/ManageClients';
import SalesProfile from './components/sales/Profile';
import AddOrder from './components/sales/AddOrder';
import ShowOrders from './components/sales/ShowOrders';
import OrderDetail from './components/admin/OrderDetail';
import SalesOrderDetail from './components/sales/OrderDetail';
import GenerateInvoice from './components/sales/GenerateInvoice'; // Import GenerateInvoice
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './AuthContext';
import { NotificationProvider } from './NotificationContext';
import Login from './components/auth/Login';
import NotAuthorized from './components/auth/NotAuthorized';
import SalesClients from './components/sales/Clients';
import Clients from './components/admin/Clients';
import ClientInvoices from './components/admin/ClientInvoices';
import SalesClientInvoices from './components/sales/ClientInvoices';
import ManageSales from './components/admin/ManageSales';
import SalesInvoices from './components/admin/SalesInvoices';
import ManageStock from './components/admin/ManageStock';
import ProductDetail from './components/ProductDetail';
import DetailedStatement from './components/sales/DetailedStatement';

const App = () => {
  return (
    <AuthProvider>
      <NotificationProvider>
        <Router>
          <Routes>
            <Route path="/view-products" element={<ProductDisplay />} />
            <Route path="/sales-dashboard" element={
              <ProtectedRoute requiredRole="sales">
                <SalesDashboard />
              </ProtectedRoute>
            } />
            <Route path="/admin-dashboard" element={
              <ProtectedRoute requiredRole="admin">
                <AdminDashboard />
              </ProtectedRoute>
            } />
            <Route path="/add-user" element={
              <ProtectedRoute requiredRole="admin">
                <AddUser />
              </ProtectedRoute>
            } />
            <Route path="/add-product" element={
              <ProtectedRoute requiredRole="admin">
                <AddProduct />
              </ProtectedRoute>
            } />
            <Route path="/add-client" element={
              <ProtectedRoute requiredRole="admin">
                <AddClient />
              </ProtectedRoute>
            } />
            <Route path="/manage-products" element={
              <ProtectedRoute requiredRole="admin">
                <ManageProducts />
              </ProtectedRoute>
            } />
            <Route path="/manage-clients" element={
              <ProtectedRoute requiredRole="admin">
                <ManageClients />
              </ProtectedRoute>
            } />
            <Route path="/manage-users" element={
              <ProtectedRoute requiredRole="admin">
                <ManageUsers />
              </ProtectedRoute>
            } />
            <Route path="/manage-orders" element={
              <ProtectedRoute requiredRole="admin">
                <ManageOrders />
              </ProtectedRoute>
            } />
            <Route path="/profile" element={
              <ProtectedRoute requiredRole="admin">
                <Profile />
              </ProtectedRoute>
            } />
            <Route path="/sales/add-client" element={
              <ProtectedRoute requiredRole="sales">
                <SalesAddClient />
              </ProtectedRoute>
            } />
            <Route path="/sales/manage-clients" element={
              <ProtectedRoute requiredRole="sales">
                <SalesManageClients />
              </ProtectedRoute>
            } />
            <Route path="/sales/profile" element={
              <ProtectedRoute requiredRole="sales">
                <SalesProfile />
              </ProtectedRoute>
            } />
            <Route path="/sales/add-order" element={
              <ProtectedRoute requiredRole="sales">
                <AddOrder />
              </ProtectedRoute>
            } />
            <Route path="/sales/show-orders" element={
              <ProtectedRoute requiredRole="sales">
                <ShowOrders />
              </ProtectedRoute>
            } />
            <Route path="/order-detail/:orderId" element={
              <ProtectedRoute requiredRole="admin">
                <OrderDetail />
              </ProtectedRoute>
            } />
            <Route path="/sales/order-detail/:orderId" element={
              <ProtectedRoute requiredRole="sales">
                <SalesOrderDetail />
              </ProtectedRoute>
            } />
            <Route path="/sales/clients" element={
              <ProtectedRoute requiredRole="sales">
                <SalesClients />
              </ProtectedRoute>
            } />
            <Route path="/clients" element={
              <ProtectedRoute requiredRole="admin">
                <Clients />
              </ProtectedRoute>
            } />
            <Route path="/sales/client-invoices/:clientId" element={
              <ProtectedRoute requiredRole="sales">
                <SalesClientInvoices />
              </ProtectedRoute>
            } />
            <Route path="/client-invoices/:clientId" element={
              <ProtectedRoute requiredRole="admin">
                <ClientInvoices />
              </ProtectedRoute>
            } />
            <Route path="/sales/generate-invoice/:orderId" element={
              <ProtectedRoute requiredRole="sales">
                <GenerateInvoice />
              </ProtectedRoute>
            } />
            <Route path="/manage-sales" element={
              <ProtectedRoute requiredRole="admin">
                <ManageSales />
              </ProtectedRoute>
            } />
            <Route path="/manage-stock" element={
              <ProtectedRoute requiredRole="admin">
                <ManageStock />
              </ProtectedRoute>
            } />
            <Route path="/admin/sales-invoices/:salesId" element={
              <ProtectedRoute requiredRole="admin">
                <SalesInvoices />
              </ProtectedRoute>
            } />
            <Route path="/order-detail/:orderId" element={
                <ProtectedRoute requiredRole="admin">
                  <OrderDetail />
                </ProtectedRoute>
              } />
              <Route path="/client-invoices/:clientId" element={
                <ProtectedRoute requiredRole="admin">
                  <ClientInvoices />
                </ProtectedRoute>
              } />
            <Route path="/login" element={<Login />} />
            <Route path="/not-authorized" element={<NotAuthorized />} />
            <Route path="/" element={<ProductDisplay />} />
            <Route path="/product-detail/:productId" element={<ProductDetail />} />

            <Route path="/client-invoices/:clientId" element={
              <ProtectedRoute requiredRole="sales">
                <ClientInvoices />
              </ProtectedRoute>
            } />
            
            <Route path="/client-invoices/:clientId" element={
              <ProtectedRoute requiredRole="admin">
                <ClientInvoices />
              </ProtectedRoute>
            } />

            <Route path="/detailed-statement/:clientId" element={
                <ProtectedRoute requiredRole="sales">
                  <DetailedStatement />
                </ProtectedRoute>
              } />

          </Routes>
        </Router>
      </NotificationProvider>
    </AuthProvider>
  );
};

export default App;
