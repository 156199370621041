import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCYXuLZNa2-9zjkgdr_hwQ5BaXvnQR3rA8",
  authDomain: "forooj-13293.firebaseapp.com",
  projectId: "forooj-13293",
  storageBucket: "forooj-13293.appspot.com",
  messagingSenderId: "1077027688243",
  appId: "1:1077027688243:web:79fda07f98a38b3027f846"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);

export { db, auth, storage, functions };