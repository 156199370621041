import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import '../styles/Navbar.css';

const Navbar = ({ toggleSidebar }) => {
  return (
    <div className="navbar">
      <button className="toggle-sidebar" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      <h1 className="navbar-title">منتجات الفروج الغذائية</h1>
    </div>
  );
};

export default Navbar;
