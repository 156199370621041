import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { db } from '../../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../AuthContext';
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider, signOut } from 'firebase/auth';
import { useNotification } from '../../NotificationContext';
import Modal from '../Modal';
import Navbar from '../Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faUser, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import '../../styles/AdminDashboard.css';
import '../../styles/AdminComponents.css'; // Reuse the styles from AdminComponents
import '../../styles/Profile.css';

const Profile = () => {
  const { currentUser } = useAuth();
  const notify = useNotification();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    if (currentUser) {
      const fetchUserData = async () => {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          setUser(userDoc.data());
        }
      };
      fetchUserData();
    }
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleSave = async () => {
    try {
      await updateDoc(doc(db, 'users', currentUser.uid), user);
      setEditMode(false);
      notify('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile: ', error);
      notify('Error updating profile.');
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      notify('New password and confirm password do not match.');
      return;
    }

    const auth = getAuth();
    const credential = EmailAuthProvider.credential(currentUser.email, currentPassword);

    try {
      await reauthenticateWithCredential(currentUser, credential);
      await updatePassword(currentUser, newPassword);
      setPasswordModalOpen(false);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      notify('Password updated successfully!');
    } catch (error) {
      console.error('Error updating password: ', error);
      notify('Error updating password.');
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(getAuth());
      navigate('/login');
      notify('You have been signed out.');
    } catch (error) {
      console.error('Error signing out: ', error);
      notify('Error signing out.');
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`admin-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
        <h2>Admin Panel</h2>
        <ul>
          <li><Link to="/admin-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/add-user"><FontAwesomeIcon icon={faUserPlus} /> Add User</Link></li>
          <li><Link to="/add-product"><FontAwesomeIcon icon={faBoxOpen} /> Add Product</Link></li>
          <li><Link to="/add-client"><FontAwesomeIcon icon={faUsers} /> Add Client</Link></li>
          <li><Link to="/manage-products"><FontAwesomeIcon icon={faClipboardList} /> Manage Products</Link></li>
          <li><Link to="/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/manage-users"><FontAwesomeIcon icon={faUsers} /> Manage Users</Link></li>
          <li><Link to="/manage-sales"><FontAwesomeIcon icon={faUsers} /> Sales Man</Link></li>
          <li><Link to="/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        <h1>User Profile</h1>
        <div className="profile-card">
          <div className="profile-details">
            <div>
              <label>First Name:</label>
              {editMode ? (
                <input type="text" name="firstName" value={user.firstName} onChange={handleChange} />
              ) : (
                <p>{user.firstName}</p>
              )}
            </div>
            <div>
              <label>Last Name:</label>
              {editMode ? (
                <input type="text" name="lastName" value={user.lastName} onChange={handleChange} />
              ) : (
                <p>{user.lastName}</p>
              )}
            </div>
            <div>
              <label>Phone Number:</label>
              {editMode ? (
                <input type="text" name="phoneNumber" value={user.phoneNumber} onChange={handleChange} />
              ) : (
                <p>{user.phoneNumber}</p>
              )}
            </div>
            <div>
              <label>Address:</label>
              {editMode ? (
                <input type="text" name="address" value={user.address} onChange={handleChange} />
              ) : (
                <p>{user.address}</p>
              )}
            </div>
            <div>
              <label>Email:</label>
              <p>{user.email}</p>
            </div>
            <div>
              <label>Role:</label>
              <p>{user.role}</p>
            </div>
            {editMode ? (
              <button onClick={handleSave}>Save</button>
            ) : (
              <button onClick={() => setEditMode(true)}>Edit</button>
            )}
          </div>
          <div className="change-password">
            <button onClick={() => setPasswordModalOpen(true)}>Change Password</button>
          </div>
          <div className="sign-out">
            <button onClick={handleSignOut}>Sign Out</button>
          </div>
        </div>
        <Modal isOpen={passwordModalOpen} onClose={() => setPasswordModalOpen(false)}>
          <h2>Change Password</h2>
          <input
            type="password"
            placeholder="Current Password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button onClick={handleChangePassword}>Submit</button>
        </Modal>
      </div>
    </div>
  );
};

export default Profile;
