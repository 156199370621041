// src/NotificationContext.js
import React, { createContext, useContext } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NotificationContext = createContext();

export const useNotification = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  const notify = (message) => {
    toast(message);
  };

  return (
    <NotificationContext.Provider value={notify}>
      {children}
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </NotificationContext.Provider>
  );
};
