import React, { useState, useMemo, useContext } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, addDoc, doc, updateDoc, increment, setDoc, getDoc } from 'firebase/firestore';
import { useNotification } from '../../NotificationContext';
import Select from 'react-select';
import Navbar from '../Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faUser, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../../AuthContext';
import '../../styles/SalesDashboard.css';
import '../../styles/AdminComponents.css';
import '../../styles/AddOrder.css';
import { useFetchClients, useFetchProducts } from '../hooks/useDataFetch';

const AddOrder = () => {
  const [order, setOrder] = useState({
    clientId: '',
    items: [],
    total: 0,
    orderNumber: '',
    salesId: '',
    status: 'pending'
  });
  const [selectedClient, setSelectedClient] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { clients, loading: clientsLoading } = useFetchClients();
  const { products, loading: productsLoading } = useFetchProducts();
  const notify = useNotification();
  const { currentUser } = useContext(AuthContext);

  const clientOptions = useMemo(() => {
    return clients.map(client => ({
      value: client.id,
      label: `${client.firstName} ${client.lastName}`,
    }));
  }, [clients]);

  const handleClientChange = (selectedOption) => {
    const client = clients.find(c => c.id === selectedOption.value);
    setSelectedClient(client);
    setOrder({ ...order, clientId: selectedOption.value });
  };

  const handleIncrementQuantity = (productId) => {
    const updatedItems = [...order.items];
    const itemIndex = updatedItems.findIndex(item => item.id === productId);

    if (itemIndex !== -1) {
      updatedItems[itemIndex].quantity += 1;
    } else {
      const selectedProduct = products.find(p => p.id === productId);
      updatedItems.push({
        id: selectedProduct.id,
        name: selectedProduct.name,
        price: selectedProduct.price,
        image: selectedProduct.image,
        quantity: 1,
      });
    }

    setOrder({ ...order, items: updatedItems });
    calculateTotal(updatedItems);
  };

  const handleDecrementQuantity = (productId) => {
    const updatedItems = [...order.items];
    const itemIndex = updatedItems.findIndex(item => item.id === productId);

    if (itemIndex !== -1 && updatedItems[itemIndex].quantity > 1) {
      updatedItems[itemIndex].quantity -= 1;
    } else if (itemIndex !== -1) {
      updatedItems.splice(itemIndex, 1);
    }

    setOrder({ ...order, items: updatedItems });
    calculateTotal(updatedItems);
  };

  const handleQuantityChange = (productId, quantity) => {
    const updatedItems = [...order.items];
    const itemIndex = updatedItems.findIndex(item => item.id === productId);

    if (itemIndex !== -1) {
      updatedItems[itemIndex].quantity = parseInt(quantity) || 0;
    }

    setOrder({ ...order, items: updatedItems });
    calculateTotal(updatedItems);
  };

  const calculateTotal = (items) => {
    const total = items.reduce((sum, item) => sum + (item.price * item.quantity), 0);
    setOrder(order => ({ ...order, total }));
  };

  const handleClientInfoChange = (e) => {
    const { name, value } = e.target;
    setSelectedClient({ ...selectedClient, [name]: value });
  };

  const handleSaveClientInfo = async () => {
    try {
      await updateDoc(doc(db, 'clients', selectedClient.id), selectedClient);
      notify('Client information updated successfully!');
    } catch (error) {
      console.error('Error updating client information:', error);
      notify('Error updating client information.');
    }
  };

  const handleStatusChange = (e) => {
    const status = e.target.value;
    setOrder({ ...order, status });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!order.clientId) {
      notify('Please select a client before adding an order.');
      return;
    }

    if (order.items.length === 0) {
      notify('Please add at least one item to the order.');
      return;
    }

    try {
      const orderNumberDoc = doc(db, 'orderNumbers', 'currentOrderNumber');
      const orderNumberSnapshot = await getDoc(orderNumberDoc);
      let orderNumber = 1;

      if (orderNumberSnapshot.exists()) {
        orderNumber = orderNumberSnapshot.data().number + 1;
        await updateDoc(orderNumberDoc, { number: increment(1) });
      } else {
        await setDoc(orderNumberDoc, { number: 1 });
      }

      const newOrder = {
        ...order,
        orderNumber,
        salesId: currentUser.uid,
      };

      await addDoc(collection(db, 'orders'), newOrder);

      for (const item of order.items) {
        const productRef = doc(db, 'products', item.id);
        await updateDoc(productRef, {
          quantity: increment(-item.quantity),
        });
      }

      setOrder({
        clientId: '',
        items: [],
        total: 0,
        orderNumber: '',
        salesId: '',
        status: 'pending',
      });

      notify('Order added and stock updated successfully!');
    } catch (error) {
      console.error('Error adding order:', error);
      notify('Error adding order.');
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={`sales-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
        <h2>Sales Panel</h2>
        <ul>
          <li><Link to="/sales-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/sales/add-client"><FontAwesomeIcon icon={faUserPlus} /> Add Client</Link></li>
          <li><Link to="/sales/add-order"><FontAwesomeIcon icon={faBoxOpen} /> Add Order</Link></li>
          <li><Link to="/sales/show-orders"><FontAwesomeIcon icon={faClipboardList} /> Show Orders</Link></li>
          <li><Link to="/sales/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/sales/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/sales/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        <h1>Add Order</h1>
        {clientsLoading || productsLoading ? (
          <p>Loading...</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Client:</label>
              <Select
                options={clientOptions}
                onChange={handleClientChange}
                placeholder="Select Client"
                isClearable
              />
            </div>
            {selectedClient && (
              <div className="client-info">
                <h2>Edit Client Information</h2>
                <div className="form-group">
                  <label>Client Name:</label>
                  <input
                    type="text"
                    name="firstName"
                    value={selectedClient.firstName}
                    onChange={handleClientInfoChange}
                  />
                </div>
                <div className="form-group">
                  <label>Client Phone Number:</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={selectedClient.phoneNumber}
                    onChange={handleClientInfoChange}
                  />
                </div>
                <div className="form-group">
                  <label>Client Address:</label>
                  <input
                    type="text"
                    name="address"
                    value={selectedClient.address}
                    onChange={handleClientInfoChange}
                  />
                </div>
                <button type="button" onClick={handleSaveClientInfo}>Save Client Info</button>
              </div>
            )}
            <div className="product-list">
              {products.map(product => (
                <div key={product.id} className="product-item">
                  <img src={product.image} alt={product.name} className="product-image" />
                  <div className="product-details">
                    <h3>{product.name}</h3>
                    <p>₪{product.price}</p>
                    <div className="quantity-control">
                      <button type="button" onClick={() => handleDecrementQuantity(product.id)}>-</button>
                      <input
                        type="number"
                        value={order.items.find(item => item.id === product.id)?.quantity || 0}
                        onChange={(e) => handleQuantityChange(product.id, e.target.value)}
                        className="quantity-input"
                      />
                      <button type="button" onClick={() => handleIncrementQuantity(product.id)}>+</button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="form-group">
              <label>Total:</label>
              <input
                type="number"
                name="total"
                value={order.total}
                readOnly
              />
            </div>
            {/* <div className="form-group">
              <label>Status:</label>
              <select value={order.status} onChange={handleStatusChange}>
                <option value="pending">Pending</option>
                <option value="Completed">Completed</option>
              </select>
            </div> */}
            <button type="submit" disabled={!order.clientId || order.items.length === 0}>Add Order</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddOrder;
