// src/components/NotAuthorized.js
import React from 'react';
import '../../styles/NotAuthorized.css';

const NotAuthorized = () => {
  return (
    <div className="not-authorized">
      <h1>Not Authorized</h1>
      <p>You do not have permission to view this page.</p>
    </div>
  );
};

export default NotAuthorized;
