import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { useNotification } from '../../NotificationContext';

export const useFetchClients = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const notify = useNotification();

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsCollection = collection(db, 'clients');
        const clientsSnapshot = await getDocs(clientsCollection);
        setClients(clientsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error('Error fetching clients:', error);
        notify('Error fetching clients.');
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, [notify]);

  return { clients, loading };
};

export const useFetchProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const notify = useNotification();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, 'products');
        const productsSnapshot = await getDocs(productsCollection);
        setProducts(productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error('Error fetching products:', error);
        notify('Error fetching products.');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [notify]);

  return { products, loading };
};
