import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import Navbar from '../Navbar';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars, faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faUser, faFileInvoice, faPrint, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import '../../styles/SalesDashboard.css';
import '../../styles/GenerateInvoice.css';
import logo from '../../images/logo.png';

const GenerateInvoice = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [client, setClient] = useState(null);
  const [sales, setSales] = useState(null);
  const [payments, setPayments] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const orderDoc = await getDoc(doc(db, 'orders', orderId));
      if (orderDoc.exists()) {
        const orderData = orderDoc.data();
        setOrder({
          ...orderData,
          date: orderData.date ? orderData.date.toDate() : new Date(), // Handle Firestore Timestamp
        });

        const clientDoc = await getDoc(doc(db, 'clients', orderData.clientId));
        if (clientDoc.exists()) {
          setClient(clientDoc.data());
        }

        const salesDoc = await getDoc(doc(db, 'users', orderData.salesId));
        if (salesDoc.exists()) {
          setSales(salesDoc.data());
        }

        const paymentsCollection = collection(db, `orders/${orderId}/payments`);
        const paymentsSnapshot = await getDocs(paymentsCollection);
        setPayments(paymentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleDownloadInvoice = async () => {
    const invoiceElement = document.getElementById('invoice');
    const canvas = await html2canvas(invoiceElement, { scale: 2 });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width, canvas.height]
    });
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
    pdf.save(`invoice_${order.orderNumber}.pdf`);
  };

  const handlePrintInvoice = () => {
    const printContents = document.getElementById('invoice').outerHTML;
    const printWindow = window.open('', '', 'height=500, width=500');
    printWindow.document.write('<html><head><title>Invoice</title>');
    printWindow.document.write('<style>');
    printWindow.document.write(`
      .invoice {
        max-width: 400px;
        margin: 0 auto;
        padding: 20px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }
      .invoice-header {
        text-align: center;
        margin-bottom: 20px;
      }
      .company-logo {
        width: 80px;
        height: auto;
        margin-bottom: 10px;
      }
      .company-name {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .invoice-details {
        margin-bottom: 20px;
        font-size: 14px;
      }
      .invoice-section {
        margin-bottom: 10px;
        padding: 10px;
        background: #f9f9f9;
        border-radius: 5px;
      }
      .invoice-section h3 {
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: bold;
        color: #333;
      }
      .invoice-items {
        margin-bottom: 20px;
      }
      .items-table {
        width: 100%;
        border-collapse: collapse;
        font-size: 14px;
      }
      .items-table th, .items-table td {
        border: 1px solid #ddd;
        padding: 8px;
      }
      .items-table th {
        text-align: left;
      }
      .invoice-total {
        text-align: right;
        font-size: 16px;
        font-weight: bold;
        color: #333;
      }
      @media print {
        body * {
          visibility: hidden;
        }
        #invoice, #invoice * {
          visibility: visible;
        }
        #invoice {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          margin: auto;
          border: none;
          box-shadow: none;
          width: 400px;
        }
      }
      .invoice-summary {
        width: 100%;
        margin-top: 20px;
        font-size: 14px;
      }
      .invoice-summary td {
        padding: 5px 0;
        border-top: 1px solid #ddd;
      }
      .invoice-summary .label {
        text-align: right;
        padding-right: 10px;
      }
    `);
    printWindow.document.write('</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(printContents);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  if (!order || !client || !sales) {
    return <div>Loading...</div>;
  }

  const totalPayments = payments.reduce((sum, payment) => sum + payment.amount, 0);
  const discountAmount =
    order.discountType === 'percentage'
      ? (order.subtotal * order.discountValue) / 100
      : order.discountValue;
  const finalTotal = order.subtotal - discountAmount - totalPayments;

  return (
    <div className={`sales-dashboard ${isSidebarOpen ? 'sidebar-closed' : 'sidebar-closed'}`}>
    {/* <div className={`sales-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}> */}
      <Navbar/>
      {/* <Navbar toggleSidebar={toggleSidebar} /> */}
      {/* <div className="sidebar">
        <h2>Sales Panel</h2>
        <ul>
          <li><Link to="/sales-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/sales/add-client"><FontAwesomeIcon icon={faUserPlus} /> Add Client</Link></li>
          <li><Link to="/sales/add-order"><FontAwesomeIcon icon={faBoxOpen} /> Add Order</Link></li>
          <li><Link to="/sales/show-orders"><FontAwesomeIcon icon={faClipboardList} /> Show Orders</Link></li>
          <li><Link to="/sales/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/sales/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/sales/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div> */}
      <div className="dashboard-content">
        <h1>Invoice</h1>
        <div id="invoice" className="invoice">
          <div className="invoice-header">
            <img src={logo} alt="Company Logo" className="company-logo" />
            <div className="company-name">منتجات الفروج الغذائية</div>
            <h4>1234567890</h4>
            <h2>Invoice</h2>
            {/* <p>Order Number: {order.orderNumber}</p> */}
            <p>Date: {order.date.toLocaleString()}</p>
            <p>Status: {order.status}</p>
            <p>{sales.firstName} {sales.lastName} - {sales.phoneNumber}</p>
          </div>
          <div className="invoice-details">
            <div className="invoice-section">
              <h3>Client Information</h3>
              <p>{client.firstName} {client.lastName} - {client.phoneNumber} - {client.address}</p>
              {/* <p></p>
              <p></p> */}
            </div>
            {/* <div className="invoice-section">
              <h3>Sales Information</h3>
              <p>{sales.firstName} {sales.lastName}</p>
              <p>{sales.phoneNumber}</p>
            </div> */}
          </div>
          <div className="invoice-items">
            <h3>Items:</h3>
            <table className="items-table">
              <thead>
                <tr>
                  {/* <th>Product</th> */}
                  <th>المنتج</th>
                  {/* <th>Price</th> */}
                  <th>السعر</th>
                  {/* <th>Quantity</th> */}
                  <th>الكمية</th>
                  {/* <th>Total</th> */}
                  <th>المجموع</th>
                </tr>
              </thead>
              <tbody>
                {order.items.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>₪{parseFloat(item.price).toFixed(2)}</td>
                    <td>{item.quantity}</td>
                    <td>₪{(parseFloat(item.price) * item.quantity).toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <table className="invoice-summary">
            <tbody>
              <tr>
                <td className="label">Subtotal:</td>
                <td>₪{order.subtotal.toFixed(2)}</td>
              </tr>
              <tr>
                <td className="label">Discount ({order.discountType === 'fixed' ? '$' : '%'} {order.discountType === 'percentage' ? order.discountValue : ''}):</td>
                <td>-₪{discountAmount.toFixed(2)}</td>
              </tr>
              <tr>
                <td className="label">Payments:</td>
                <td>₪{totalPayments.toFixed(2)}</td>
              </tr>
              <tr>
                <td className="label">Total:</td>
                <td>₪{finalTotal.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="invoice-actions">
          <button onClick={handleDownloadInvoice}>Download Invoice</button>
          <button onClick={handlePrintInvoice}>Print Invoice</button>
        </div>
      </div>
    </div>
  );
};

export default GenerateInvoice;
