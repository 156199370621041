// src/components/ManageOrders.js
import React, { useEffect, useState } from 'react';
import { db } from '../../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import '../../styles/ManageOrders.css';

const ManageOrders = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      const ordersCollection = collection(db, 'orders');
      const ordersSnapshot = await getDocs(ordersCollection);
      setOrders(ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchOrders();
  }, []);

  return (
    <div className="manage-orders">
      <h1>Manage Orders</h1>
      {orders.map(order => (
        <div key={order.id} className="order-card">
          <h2>Order ID: {order.id}</h2>
          <p>Customer: {order.customerName}</p>
          <p>Total: ₪{order.total}</p>
        </div>
      ))}
    </div>
  );
};

export default ManageOrders;
