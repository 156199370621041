import React, { useEffect, useReducer, useMemo, useCallback, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { doc, getDoc, updateDoc, collection, getDocs, addDoc, deleteDoc } from 'firebase/firestore';
import Navbar from '../Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faUser, faEdit, faSave, faFileInvoice, faMoneyBillWave, faTrash, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../../AuthContext';
import { useNotification } from '../../NotificationContext';
import Select from 'react-select';
import '../../styles/SalesDashboard.css';
import '../../styles/OrderDetail.css';
import Modal from '../Modal';

const initialState = {
  order: {
    items: [],
    subtotal: 0,
    total: 0,
    discountAmount: 0,
    salesNote: '',
  },
  client: null,
  sales: null,
  status: '',
  products: [],
  payments: [],
  isSidebarOpen: true,
  editMode: false,
  paymentModalOpen: false,
  paymentAmount: '',
  editPaymentId: null,
  discountType: 'fixed',
  discountValue: 0,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_ORDER_DETAILS':
      return { ...state, ...action.payload };
    case 'SET_STATUS':
      return { ...state, status: action.payload };
    case 'SET_ORDER_ITEMS':
      return { ...state, order: { ...state.order, items: action.payload } };
    case 'SET_DISCOUNT_TYPE':
      return { ...state, discountType: action.payload };
    case 'SET_DISCOUNT_VALUE':
      return { ...state, discountValue: action.payload };
    case 'TOGGLE_SIDEBAR':
      return { ...state, isSidebarOpen: !state.isSidebarOpen };
    case 'TOGGLE_EDIT_MODE':
      return { ...state, editMode: !state.editMode };
    case 'SET_PAYMENT_MODAL_OPEN':
      return { ...state, paymentModalOpen: action.payload };
    case 'SET_PAYMENT_AMOUNT':
      return { ...state, paymentAmount: action.payload };
    case 'SET_EDIT_PAYMENT_ID':
      return { ...state, editPaymentId: action.payload };
    case 'UPDATE_ORDER':
      return { ...state, order: { ...state.order, ...action.payload } };
    case 'SET_PRODUCTS':
      return { ...state, products: action.payload };
    case 'SET_PAYMENTS':
      return { ...state, payments: action.payload };
    case 'SET_CLIENT':
      return { ...state, client: action.payload };
    case 'SET_SALES':
      return { ...state, sales: action.payload };
    default:
      return state;
  }
}

const OrderDetail = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentUser } = useContext(AuthContext);
  const notify = useNotification();

  // Ensure useMemo is called unconditionally
  const productOptions = useMemo(() => state.products.map(product => ({
    value: product.id,
    label: `${product.name} - ₪${product.price}`,
  })), [state.products]);

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  useEffect(() => {
    calculateTotal(state.order.items, state.discountType, state.discountValue);
  }, [state.order.items, state.discountType, state.discountValue, state.payments]);

  const fetchOrderDetails = useCallback(async () => {
    try {
      const orderDoc = await getDoc(doc(db, 'orders', orderId));
      if (orderDoc.exists()) {
        const orderData = orderDoc.data();
        dispatch({
          type: 'SET_ORDER_DETAILS',
          payload: {
            order: {
              ...orderData,
              subtotal: orderData.subtotal || 0,
              total: orderData.total || 0,
              discountAmount: orderData.discountAmount || 0,
              salesNote: orderData.salesNote || '',
            },
            status: orderData.status,
            discountType: orderData.discountType || 'fixed',
            discountValue: orderData.discountValue || 0,
          },
        });

        const clientDoc = await getDoc(doc(db, 'clients', orderData.clientId));
        if (clientDoc.exists()) {
          dispatch({ type: 'SET_CLIENT', payload: clientDoc.data() });
        }

        const salesDoc = await getDoc(doc(db, 'users', orderData.salesId));
        if (salesDoc.exists()) {
          dispatch({ type: 'SET_SALES', payload: salesDoc.data() });
        }

        const productsSnapshot = await getDocs(collection(db, 'products'));
        dispatch({ type: 'SET_PRODUCTS', payload: productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) });

        const paymentsSnapshot = await getDocs(collection(db, `orders/${orderId}/payments`));
        dispatch({ type: 'SET_PAYMENTS', payload: paymentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) });
      }
    } catch (error) {
      console.error("Error fetching order details: ", error);
      notify('Error fetching order details.');
    }
  }, [orderId, notify]);

  const updateFirestoreOrder = useCallback(async (updateData) => {
    try {
      await updateDoc(doc(db, 'orders', orderId), {
        ...updateData
      });
      notify('Order updated successfully!');
    } catch (error) {
      console.error('Error updating order in Firestore: ', error);
      notify('Error updating order in Firestore.');
    }
  }, [orderId, notify]);

  const calculateTotal = useCallback((items, discountType, discountValue) => {
    const subtotal = items.reduce((sum, item) => sum + (parseFloat(item.price) * item.quantity), 0);
    let discountAmount = 0;
    if (discountType === 'fixed') {
      discountAmount = discountValue;
    } else if (discountType === 'percentage') {
      discountAmount = (subtotal * discountValue) / 100;
    }
    const totalPayments = state.payments.reduce((sum, payment) => sum + payment.amount, 0);
    const total = subtotal - discountAmount - totalPayments;
    dispatch({ type: 'UPDATE_ORDER', payload: { subtotal, total: Math.max(total, 0), discountAmount } });
    updateOrderStatus(totalPayments, subtotal, discountAmount);
    updateFirestoreOrder({ subtotal, total: Math.max(total, 0), discountAmount });
  }, [state.payments, updateFirestoreOrder]);

  const updateOrderStatus = (totalPayments, subtotal, discountAmount) => {
    const total = subtotal - discountAmount;
    let newStatus = state.status;
    if (totalPayments >= total) {
      newStatus = 'complete';
    } else if (totalPayments > 0) {
      newStatus = 'partial';
    } else {
      newStatus = 'pending';
    }
    dispatch({ type: 'SET_STATUS', payload: newStatus });
  };

  const handleStatusChange = (e) => {
    dispatch({ type: 'SET_STATUS', payload: e.target.value });
  };

  const handleProductChange = (index, selectedOption) => {
    const updatedItems = [...state.order.items];
    const selectedProduct = state.products.find(p => p.id === selectedOption.value);
    updatedItems[index] = {
      id: selectedProduct.id,
      name: selectedProduct.name,
      price: parseFloat(selectedProduct.price),
      quantity: updatedItems[index].quantity,
    };
    dispatch({ type: 'SET_ORDER_ITEMS', payload: updatedItems });
  };

  const handleQuantityChange = (index, quantity) => {
    const updatedItems = [...state.order.items];
    updatedItems[index].quantity = parseFloat(quantity);
    dispatch({ type: 'SET_ORDER_ITEMS', payload: updatedItems });
  };

  const handleDiscountTypeChange = (type) => {
    dispatch({ type: 'SET_DISCOUNT_TYPE', payload: type });
    calculateTotal(state.order.items, type, state.discountValue);
  };

  const handleDiscountValueChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    dispatch({ type: 'SET_DISCOUNT_VALUE', payload: value });
    calculateTotal(state.order.items, state.discountType, value);
  };

  const handleSaveOrder = async () => {
    try {
      const subtotal = state.order.items.reduce((sum, item) => sum + (parseFloat(item.price) * item.quantity), 0);
      let discountAmount = 0;
      if (state.discountType === 'fixed') {
        discountAmount = state.discountValue;
      } else if (state.discountType === 'percentage') {
        discountAmount = (subtotal * state.discountValue) / 100;
      }

      const totalPayments = state.payments.reduce((sum, payment) => sum + payment.amount, 0);
      const total = subtotal - discountAmount - totalPayments;

      const updatedOrder = {
        subtotal,
        discountAmount,
        total: Math.max(total, 0),
        status: state.status,
        discountType: state.discountType,
        discountValue: state.discountValue,
        salesNote: state.order.salesNote,
      };

      dispatch({ type: 'UPDATE_ORDER', payload: updatedOrder });

      await updateFirestoreOrder(updatedOrder);

      dispatch({ type: 'TOGGLE_EDIT_MODE' });
    } catch (error) {
      console.error('Error updating order: ', error);
      notify('Error updating order.');
    }
  };

  const handlePayment = async () => {
    if (!state.paymentAmount) {
      notify('You should enter a value.');
      return;
    }

    const paymentValue = parseFloat(state.paymentAmount);
    if (paymentValue <= 0.000000001) {
      notify('Payment amount cannot be zero or less.');
      return;
    }

    if (paymentValue > state.order.total) {
      notify('Payment amount cannot exceed the total remaining amount.');
      return;
    }

    try {
      const paymentData = {
        amount: paymentValue,
        timestamp: new Date(),
      };

      if (state.editPaymentId) {
        const paymentRef = doc(db, `orders/${orderId}/payments`, state.editPaymentId);
        await updateDoc(paymentRef, paymentData);
        dispatch({
          type: 'SET_PAYMENTS',
          payload: state.payments.map(payment =>
            payment.id === state.editPaymentId ? { ...payment, ...paymentData } : payment
          ),
        });
        dispatch({ type: 'SET_EDIT_PAYMENT_ID', payload: null });
      } else {
        const paymentRef = await addDoc(collection(db, `orders/${orderId}/payments`), paymentData);
        dispatch({ type: 'SET_PAYMENTS', payload: [...state.payments, { ...paymentData, id: paymentRef.id }] });
      }

      calculateTotal(state.order.items, state.discountType, state.discountValue);

      dispatch({ type: 'SET_PAYMENT_AMOUNT', payload: '' });
      dispatch({ type: 'SET_PAYMENT_MODAL_OPEN', payload: false });
      notify('Payment processed successfully!');
    } catch (error) {
      console.error('Error processing payment: ', error);
      notify('Error processing payment.');
    }
  };

  const handleEditPayment = (payment) => {
    dispatch({ type: 'SET_PAYMENT_AMOUNT', payload: payment.amount });
    dispatch({ type: 'SET_EDIT_PAYMENT_ID', payload: payment.id });
    dispatch({ type: 'SET_PAYMENT_MODAL_OPEN', payload: true });
  };

  const handleDeletePayment = async (paymentId, amount) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this payment?");
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, `orders/${orderId}/payments`, paymentId));
      dispatch({ type: 'SET_PAYMENTS', payload: state.payments.filter(payment => payment.id !== paymentId) });
      calculateTotal(state.order.items, state.discountType, state.discountValue);
      notify('Payment deleted successfully!');
    } catch (error) {
      console.error('Error deleting payment: ', error);
      notify('Error deleting payment.');
    }
  };

  const handleSalesNoteChange = (e) => {
    dispatch({ type: 'UPDATE_ORDER', payload: { salesNote: e.target.value } });
  };

  const toggleSidebar = () => {
    dispatch({ type: 'TOGGLE_SIDEBAR' });
  };

  if (!state.order || !state.client || !state.sales) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`sales-dashboard ${state.isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
        <h2>Sales Panel</h2>
        <ul>
          <li><Link to="/sales-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/sales/add-client"><FontAwesomeIcon icon={faUserPlus} /> Add Client</Link></li>
          <li><Link to="/sales/add-order"><FontAwesomeIcon icon={faBoxOpen} /> Add Order</Link></li>
          <li><Link to="/sales/show-orders"><FontAwesomeIcon icon={faClipboardList} /> Show Orders</Link></li>
          <li><Link to="/sales/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/sales/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/sales/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        <h1>Order Detail</h1>
        <div className="order-detail">
          <OrderInfo
            order={state.order}
            client={state.client}
            sales={state.sales}
            status={state.status}
            editMode={state.editMode}
            handleStatusChange={handleStatusChange}
            discountType={state.discountType}
            discountValue={state.discountValue}
            handleDiscountTypeChange={handleDiscountTypeChange}
            handleDiscountValueChange={handleDiscountValueChange}
          />
          <OrderItems
            items={state.order.items}
            products={state.products}
            editMode={state.editMode}
            handleProductChange={handleProductChange}
            handleQuantityChange={handleQuantityChange}
          />
          <OrderPayments
            payments={state.payments}
            handleEditPayment={handleEditPayment}
            handleDeletePayment={handleDeletePayment}
          />
          {state.editMode ? (
            <div className="form-group">
              <label>Sales Note:</label>
              <textarea
                value={state.order.salesNote}
                onChange={handleSalesNoteChange}
                placeholder="Add a note for sales"
                className="input-field"
              ></textarea>
            </div>
          ) : (
            <div className="form-group">
              <label>Sales Note:</label>
              <p>{state.order.salesNote}</p>
            </div>
          )}
          <div className="buttons">
            {state.editMode ? (
              <button onClick={handleSaveOrder} className="save-button">
                <FontAwesomeIcon icon={faSave} /> Save Order
              </button>
            ) : (
              <button onClick={() => dispatch({ type: 'TOGGLE_EDIT_MODE' })} className="edit-button">
                <FontAwesomeIcon icon={faEdit} /> Edit
              </button>
            )}
            <button
              onClick={() => navigate(`/sales/generate-invoice/${orderId}`)}
              className="invoice-button"
            >
              <FontAwesomeIcon icon={faFileInvoice} /> Generate Invoice
            </button>
            <button
              onClick={() => dispatch({ type: 'SET_PAYMENT_MODAL_OPEN', payload: true })}
              className="payment-button"
            >
              <FontAwesomeIcon icon={faMoneyBillWave} /> Add Payment
            </button>
          </div>
        </div>
      </div>
      <Modal isOpen={state.paymentModalOpen} onClose={() => dispatch({ type: 'SET_PAYMENT_MODAL_OPEN', payload: false })}>
        <h2>{state.editPaymentId ? 'Edit Payment' : 'Add Payment'}</h2>
        <div className="form-group">
          <label>Payment Amount:</label>
          <input
            type="number"
            min="0.0000000001"
            max={state.order.total}
            placeholder={`Total: ₪${state.order.total}`}
            value={state.paymentAmount}
            onChange={(e) => dispatch({ type: 'SET_PAYMENT_AMOUNT', payload: e.target.value ? parseFloat(e.target.value) : '' })}
          />
        </div>
        <button onClick={handlePayment} className="save-button">
          {state.editPaymentId ? 'Update Payment' : 'Add Payment'}
        </button>
      </Modal>
    </div>
  );
};

const OrderInfo = ({ order, client, sales, status, editMode, handleStatusChange, discountType, discountValue, handleDiscountTypeChange, handleDiscountValueChange }) => {
  return (
    <div className="detail-row">
      <div className="form-group">
        <label>Order Number:</label>
        <p>{order.orderNumber}</p>
      </div>
      <div className="form-group">
        <label>Client:</label>
        <p>{client.firstName} {client.lastName}</p>
      </div>
      <div className="form-group">
        <label>Sales:</label>
        <p>{sales.firstName} {sales.lastName}</p>
      </div>
      <div className="form-group">
        <label>Subtotal:</label>
        <p>₪{order.subtotal.toFixed(2)}</p>
      </div>
      <div className="form-group">
        <label>Total:</label>
        <p>₪{order.total.toFixed(2)}</p>
      </div>
      <div className="form-group">
        <label>Order Status:</label>
        {editMode ? (
          <select
            className="select-field"
            value={status}
            onChange={handleStatusChange}
            style={{
              backgroundColor:
                status === 'complete'
                  ? 'green'
                  : status === 'partial'
                    ? 'blue'
                    : status === 'pending'
                      ? 'red'
                      : 'inherit',
              color: 'white',
            }}
          >
            <option value="pending">Pending</option>
            {/* <option value="ready">Ready</option> */}
            <option value="canceled">Canceled</option>
            <option value="partial">Partial</option>
            <option value="complete">Complete</option>
          </select>
        ) : (
          <p
            style={{
              backgroundColor:
                status === 'complete'
                  ? 'green'
                  : status === 'partial'
                    ? 'blue'
                    : status === 'pending'
                      ? 'red'
                      : 'inherit',
              color: 'white',
              padding: '5px',
              borderRadius: '5px',
              textAlign: 'center',
            }}
          >
            {status}
          </p>
        )}
      </div>
      <div className="form-group">
        <label>Discount:</label>
        {editMode ? (
          <div className="discount-input">
            <input
              type="number"
              min="0"
              value={discountValue}
              onChange={handleDiscountValueChange}
              placeholder="Enter discount"
              className="input-field"
            />
            <button
              onClick={() => handleDiscountTypeChange('fixed')}
              className={`discount-type-button ${discountType === 'fixed' ? 'active' : ''}`}
            >
              ₪
            </button>
            <button
              onClick={() => handleDiscountTypeChange('percentage')}
              className={`discount-type-button ${discountType === 'percentage' ? 'active' : ''}`}
            >
              %
            </button>
            <p>{discountValue} {discountType === 'fixed' ? `₪` : `%`} (-₪{order.discountAmount.toFixed(2)})</p>
          </div>
        ) : (
          <p>{discountValue} {discountType === 'fixed' ? `₪` : `%`} (-₪{order.discountAmount.toFixed(2)})</p>
        )}
      </div>
    </div>
  );
};

const OrderItems = ({ items, products, editMode, handleProductChange, handleQuantityChange }) => {
  const productOptions = useMemo(() => products.map(product => ({
    value: product.id,
    label: `${product.name} - ₪${product.price}`,
  })), [products]);

  return (
    <div>
      <h3>Items:</h3>
      <table className="items-table">
        <thead>
          <tr>
            <th>Product</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td>
                {editMode ? (
                  <Select
                    options={productOptions}
                    value={productOptions.find(option => option.value === item.id)}
                    onChange={(selectedOption) => handleProductChange(index, selectedOption)}
                    isClearable
                  />
                ) : (
                  <p>{item.name}</p>
                )}
              </td>
              <td>₪{parseFloat(item.price).toFixed(2)}</td>
              <td>
                {editMode ? (
                  <input
                    type="number"
                    min="1"
                    value={item.quantity}
                    onChange={(e) => handleQuantityChange(index, e.target.value)}
                    className="input-field"
                  />
                ) : (
                  <p>{item.quantity}</p>
                )}
              </td>
              <td>₪{(parseFloat(item.price) * item.quantity).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const OrderPayments = ({ payments, handleEditPayment, handleDeletePayment }) => {
  return (
    <div>
      <h3>Payments:</h3>
      <table className="payments-table">
        <thead>
          <tr>
            <th>Amount</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment, index) => (
            <tr key={index}>
              <td>₪{payment.amount.toFixed(2)}</td>
              <td>{new Date(payment.timestamp.seconds * 1000).toLocaleString()}</td>
              <td>
                <button onClick={() => handleEditPayment(payment)} className="edit-button">
                  <FontAwesomeIcon icon={faEdit} /> Edit
                </button>
                <button onClick={() => handleDeletePayment(payment.id, payment.amount)} className="delete-button">
                  <FontAwesomeIcon icon={faTrash} /> Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderDetail;
