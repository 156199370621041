import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, getDocs, query, where, getDoc, doc, deleteDoc } from 'firebase/firestore';
import Navbar from '../Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield, faUserPlus, faBoxOpen, faUsers, faClipboardList, faUser, faAddressBook, faTrash } from '@fortawesome/free-solid-svg-icons';
import '../../styles/SalesDashboard.css';
import '../../styles/ClientInvoices.css';

const ClientInvoices = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();  // Get the navigate function from react-router
  const [invoices, setInvoices] = useState([]);
  const [client, setClient] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [invoiceToDelete, setInvoiceToDelete] = useState(null);
  const [statement, setStatement] = useState({ totalInvoiced: 0, totalPaid: 0, balanceDue: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const invoicesQuery = query(collection(db, 'orders'), where('clientId', '==', clientId));
        const invoicesSnapshot = await getDocs(invoicesQuery);
        const fetchedInvoices = invoicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        let totalInvoiced = 0;
        let totalPaid = 0;

        for (const invoice of fetchedInvoices) {
          const subtotal = parseFloat(invoice.subtotal) || 0;
          const discount = parseFloat(invoice.discountAmount) || 0;
          const invoiceTotal = subtotal - discount;
          totalInvoiced += invoiceTotal;

          const paymentsCollection = collection(db, `orders/${invoice.id}/payments`);
          const paymentsSnapshot = await getDocs(paymentsCollection);
          const invoicePayments = paymentsSnapshot.docs.map(paymentDoc => paymentDoc.data());

          const invoicePaid = invoicePayments.reduce((sum, payment) => sum + (parseFloat(payment.amount) || 0), 0);
          totalPaid += invoicePaid;

          invoice.payments = invoicePayments;
          invoice.totalPaid = invoicePaid;
        }

        const balanceDue = totalInvoiced - totalPaid;

        setInvoices(fetchedInvoices);
        setStatement({ totalInvoiced, totalPaid, balanceDue });
      } catch (error) {
        console.error('Error fetching invoices:', error);
        setError('Failed to fetch invoices. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    const fetchClient = async () => {
      try {
        const clientDoc = await getDoc(doc(db, 'clients', clientId));
        if (clientDoc.exists()) {
          setClient(clientDoc.data());
        } else {
          setError('Client not found.');
        }
      } catch (error) {
        console.error('Error fetching client:', error);
        setError('Failed to fetch client details. Please try again later.');
      }
    };

    fetchInvoices();
    fetchClient();
  }, [clientId]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleDeleteClick = (invoice) => {
    setInvoiceToDelete(invoice);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteDoc(doc(db, 'orders', invoiceToDelete.id));
      setInvoices(invoices.filter(invoice => invoice.id !== invoiceToDelete.id));
      setShowDeleteModal(false);
      setInvoiceToDelete(null);
    } catch (error) {
      console.error('Error deleting invoice:', error);
      setError('Failed to delete the invoice. Please try again later.');
    }
  };

  const handleGenerateStatementClick = () => {
    navigate(`/detailed-statement/${clientId}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!client) {
    return <div>Client not found</div>;
  }

  return (
    <div className={`sales-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
      <h2>Sales Panel</h2>
        <ul>
          <li><Link to="/sales-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/sales/add-client"><FontAwesomeIcon icon={faUserPlus} /> Add Client</Link></li>
          <li><Link to="/sales/add-order"><FontAwesomeIcon icon={faBoxOpen} /> Add Order</Link></li>
          <li><Link to="/sales/show-orders"><FontAwesomeIcon icon={faClipboardList} /> Show Orders</Link></li>
          <li><Link to="/sales/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/sales/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/sales/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        <h1>Invoices for {client.firstName} {client.lastName}</h1>
        <div className="statement-container">
          <h2>Client Statement</h2>
          <p><strong>Total Invoiced:</strong> <span className="value">₪{statement.totalInvoiced.toFixed(2)}</span></p>
          <p><strong>Total Paid:</strong> <span className="value">₪{statement.totalPaid.toFixed(2)}</span></p>
          <p><strong>Balance Due:</strong> <span className="value">₪{statement.balanceDue.toFixed(2)}</span></p>
        </div>
        <button className="generate-statement-button" onClick={handleGenerateStatementClick}>
          Generate Detailed Statement
        </button>
        <div className="invoices-container">
          {invoices.map(invoice => (
            <div key={invoice.id} className="invoice-card">
              <button onClick={() => handleDeleteClick(invoice)} className="delete-icon">
                <FontAwesomeIcon icon={faTrash} />
              </button>
              <Link to={`/order-detail/${invoice.id}`} className="invoice-link">
                <h2>Invoice Number: {invoice.orderNumber}</h2>
                <p><strong>Total:</strong> ₪{invoice.total.toFixed(2)}</p>
                <p><strong>Status:</strong> {invoice.status}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientInvoices;
