import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, getDocs, query, where } from 'firebase/firestore';
import Navbar from '../Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faUser, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import '../../styles/SalesInvoices.css';

const SalesInvoices = () => {
  const { salesId } = useParams();
  const [invoices, setInvoices] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    const fetchInvoices = async () => {
      const invoicesCollection = collection(db, 'orders');
      const q = query(invoicesCollection, where('salesId', '==', salesId));
      const invoicesSnapshot = await getDocs(q);
      setInvoices(invoicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchInvoices();
  }, [salesId]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={`admin-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
        <h2>Admin Panel</h2>
        <ul>
          <li><Link to="/admin-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/add-user"><FontAwesomeIcon icon={faUserPlus} /> Add User</Link></li>
          <li><Link to="/add-product"><FontAwesomeIcon icon={faBoxOpen} /> Add Product</Link></li>
          <li><Link to="/add-client"><FontAwesomeIcon icon={faUsers} /> Add Client</Link></li>
          <li><Link to="/manage-products"><FontAwesomeIcon icon={faClipboardList} /> Manage Products</Link></li>
          <li><Link to="/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/manage-users"><FontAwesomeIcon icon={faUsers} /> Manage Users</Link></li>
          <li><Link to="/manage-sales"><FontAwesomeIcon icon={faUsers} /> Sales Man</Link></li>
          <li><Link to="/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        <h1>Invoices for Sales User</h1>
        <div className="invoices-container">
          {invoices.map(invoice => (
            <Link to={`/order-detail/${invoice.id}`} key={invoice.id} className="invoice-card">
              <h2>Order Number: {invoice.orderNumber}</h2>
              <p><strong>Client:</strong> {invoice.clientId}</p>
              <p><strong>Total:</strong> ₪{invoice.total}</p>
              <p><strong>Status:</strong> {invoice.status}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SalesInvoices;
