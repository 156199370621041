import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import Navbar from '../Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faUser, faEdit, faTrash, faEye, faEyeSlash, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import '../../styles/ManageSales.css';

const ManageSales = () => {
  const [salesUsers, setSalesUsers] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    const fetchSalesUsers = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const salesData = usersSnapshot.docs
        .filter(doc => doc.data().role === 'sales')
        .map(doc => ({ id: doc.id, ...doc.data() }));
      setSalesUsers(salesData);
    };

    fetchSalesUsers();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={`admin-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
        <h2>Admin Panel</h2>
        <ul>
          <li><Link to="/admin-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/add-user"><FontAwesomeIcon icon={faUserPlus} /> Add User</Link></li>
          <li><Link to="/add-product"><FontAwesomeIcon icon={faBoxOpen} /> Add Product</Link></li>
          <li><Link to="/add-client"><FontAwesomeIcon icon={faUsers} /> Add Client</Link></li>
          <li><Link to="/manage-products"><FontAwesomeIcon icon={faClipboardList} /> Manage Products</Link></li>
          <li><Link to="/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/manage-users"><FontAwesomeIcon icon={faUsers} /> Manage Users</Link></li>
          <li><Link to="/manage-sales"><FontAwesomeIcon icon={faUsers} /> Sales Man</Link></li>
          <li><Link to="/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        <h1>Manage Sales</h1>
        <div className="sales-users-container">
          {salesUsers.map(user => (
            <Link to={`/admin/sales-invoices/${user.id}`} key={user.id} className="sales-user-card">
              <h2>{user.firstName} {user.lastName}</h2>
              <p><strong>Email:</strong> {user.email}</p>
              <p><strong>Phone:</strong> {user.phoneNumber}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManageSales;
