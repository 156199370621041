import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, getDocs, deleteDoc, doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { useNotification } from '../../NotificationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faUser, faEdit, faTrash, faEye, faEyeSlash, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import Modal from '../Modal';
import Navbar from '../Navbar';
import '../../styles/AdminDashboard.css';
import '../../styles/AdminComponents.css';
import '../../styles/ManageUsers.css';
import { getFunctions, httpsCallable } from 'firebase/functions';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userEditData, setUserEditData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    role: '',
    password: '',
    confirmPassword: '',
    active: true
  });
  const [newPassword, setNewPassword] = useState('');
  const [filter, setFilter] = useState('all');
  const [roleFilter, setRoleFilter] = useState('all');
  const notify = useNotification();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const functions = getFunctions();

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      setUsers(usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchUsers();
  }, []);

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, 'users', selectedUser.id));
      await deleteDoc(doc(db, 'clients', selectedUser.id));
      setUsers(users.filter(user => user.id !== selectedUser.id));
      setDeleteModalOpen(false);
      notify('User deleted successfully!');
    } catch (error) {
      console.error('Error deleting user: ', error);
      notify('Error deleting user.');
    }
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setUserEditData({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      address: user.address,
      role: user.role,
      password: '',
      confirmPassword: '',
      active: user.active
    });
    setEditModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserEditData({ ...userEditData, [name]: value });
  };

  const handleSave = async () => {
    try {
      const userRef = doc(db, 'users', selectedUser.id);
      const clientRef = doc(db, 'clients', selectedUser.id);

      await updateDoc(userRef, userEditData);

      const clientDoc = await getDoc(clientRef);
      if (clientDoc.exists()) {
        await updateDoc(clientRef, userEditData);
      } else {
        await setDoc(clientRef, {
          ...userEditData,
          uid: selectedUser.id,
        });
      }

      // Update password if provided
      if (userEditData.password && userEditData.password === userEditData.confirmPassword) {
        const updateUserPassword = httpsCallable(functions, 'updateUserPassword');
        await updateUserPassword({ uid: selectedUser.id, newPassword: userEditData.password });
        notify('Password updated successfully!');
      } else if (userEditData.password !== userEditData.confirmPassword) {
        notify('Passwords do not match.');
      }

      setUsers(users.map(user => 
        user.id === selectedUser.id ? { ...user, ...userEditData } : user
      ));
      setEditModalOpen(false);
      notify('User updated successfully!');
    } catch (error) {
      console.error('Error updating user: ', error);
      notify(`Error updating user: ${error.message}`);
    }
  };

  const toggleActive = async (user) => {
    try {
      await updateDoc(doc(db, 'users', user.id), {
        active: !user.active,
      });
      setUsers(users.map(u => 
        u.id === user.id ? { ...u, active: !u.active } : u
      ));
      notify(`User ${user.active ? 'deactivated' : 'activated'} successfully!`);
    } catch (error) {
      console.error('Error activating/deactivating user: ', error);
      notify('Error activating/deactivating user.');
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const filteredUsers = users.filter(user => {
    const roleMatch = roleFilter === 'all' || user.role === roleFilter;
    const statusMatch = filter === 'all' || (filter === 'active' && user.active) || (filter === 'inactive' && !user.active);
    return roleMatch && statusMatch;
  });

  return (
    <div className={`admin-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
        <h2>Admin Panel</h2>
        <ul>
          <li><Link to="/admin-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/add-user"><FontAwesomeIcon icon={faUserPlus} /> Add User</Link></li>
          <li><Link to="/add-product"><FontAwesomeIcon icon={faBoxOpen} /> Add Product</Link></li>
          <li><Link to="/add-client"><FontAwesomeIcon icon={faUsers} /> Add Client</Link></li>
          <li><Link to="/manage-products"><FontAwesomeIcon icon={faClipboardList} /> Manage Products</Link></li>
          <li><Link to="/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/manage-users"><FontAwesomeIcon icon={faUsers} /> Manage Users</Link></li>
          <li><Link to="/manage-sales"><FontAwesomeIcon icon={faUsers} /> Sales Man</Link></li>
          <li><Link to="/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        {/* <button className="toggle-sidebar" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faBars} />
        </button> */}
        <h1>Manage Users</h1>
        <div className="filter-dropdown">
          <select value={roleFilter} onChange={(e) => setRoleFilter(e.target.value)}>
            <option value="all">All Roles</option>
            <option value="admin">Admin</option>
            <option value="sales">Sales</option>
            <option value="client">Client</option>
          </select>
        </div>
        <div className="filter-buttons">
          <button 
            className={filter === 'active' ? 'active' : ''} 
            onClick={() => setFilter('active')}
          >
            Active Users
          </button>
          <button 
            className={filter === 'inactive' ? 'active' : ''} 
            onClick={() => setFilter('inactive')}
          >
            Inactive Users
          </button>
          <button 
            className={filter === 'all' ? 'active' : ''} 
            onClick={() => setFilter('all')}
          >
            All Users
          </button>
        </div>
        <div className="manage-users-container">
          {filteredUsers.map(user => (
            <div key={user.id} className={`user-card ${user.active ? '' : 'inactive-user'}`}>
              <div className="user-details">
                <h2>{user.firstName} {user.lastName}</h2>
                <p><strong>Email:</strong> {user.email}</p>
                <p><strong>Phone Number:</strong> {user.phoneNumber}</p>
                <p><strong>Address:</strong> {user.address}</p>
                <p><strong>Role:</strong> {user.role}</p>
                <p><strong>Active:</strong> {user.active ? 'Yes' : 'No'}</p>
              </div>
              <div className="user-actions">
                <button className="edit-button" onClick={() => handleEdit(user)}><FontAwesomeIcon icon={faEdit} /> Edit</button>
                {/* <button className="delete-button" onClick={() => { setSelectedUser(user); setDeleteModalOpen(true); }}><FontAwesomeIcon icon={faTrash} /> Delete</button> */}
                <button className="inactive-button" onClick={() => toggleActive(user)}>
                  <FontAwesomeIcon icon={user.active ? faEyeSlash : faEye} /> {user.active ? 'Deactivate' : 'Activate'}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {editModalOpen && (
        <Modal isOpen={editModalOpen} onClose={() => setEditModalOpen(false)}>
          <h2>Edit User</h2>
          <div className="form-group">
            <label>First Name:</label>
            <input
              type="text"
              name="firstName"
              value={userEditData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Last Name:</label>
            <input
              type="text"
              name="lastName"
              value={userEditData.lastName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={userEditData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Phone Number:</label>
            <input
              type="text"
              name="phoneNumber"
              value={userEditData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Address:</label>
            <input
              type="text"
              name="address"
              value={userEditData.address}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Role:</label>
            <select
              name="role"
              value={userEditData.role}
              onChange={handleChange}
              required
            >
              <option value="">Select Role</option>
              <option value="admin">Admin</option>
              <option value="sales">Sales</option>
              <option value="client">Client</option>
            </select>
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              name="password"
              placeholder="New Password"
              value={userEditData.password}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Confirm Password:</label>
            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirm New Password"
              value={userEditData.confirmPassword}
              onChange={handleChange}
            />
          </div>
          <button onClick={handleSave}>Save</button>
        </Modal>
      )}
      {deleteModalOpen && (
        <Modal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
          <h2>Delete User</h2>
          <p>Are you sure you want to delete this user?</p>
          <button onClick={handleDelete}>Yes, Delete</button>
          <button onClick={() => setDeleteModalOpen(false)}>Cancel</button>
        </Modal>
      )}
    </div>
  );
};

export default ManageUsers;
