import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faUser, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import '../../styles/SalesDashboard.css';
import Navbar from '../Navbar';


const SalesDashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={`sales-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
        <h2>Sales Panel</h2>
        <ul>
          <li><Link to="/sales-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/sales/add-client"><FontAwesomeIcon icon={faUserPlus} /> Add Client</Link></li>
          <li><Link to="/sales/add-order"><FontAwesomeIcon icon={faBoxOpen} /> Add Order</Link></li>
          <li><Link to="/sales/show-orders"><FontAwesomeIcon icon={faClipboardList} /> Show Orders</Link></li>
          <li><Link to="/sales/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/sales/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/sales/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        <div className="card-grid">
          <div className="card">
            <Link to="/sales/add-client">
              <FontAwesomeIcon icon={faUserPlus} size="3x" />
              <h3>Add Client</h3>
            </Link>
          </div>
          <div className="card">
            <Link to="/sales/add-order">
              <FontAwesomeIcon icon={faBoxOpen} size="3x" />
              <h3>Add Order</h3>
            </Link>
          </div>
          <div className="card">
            <Link to="/sales/show-orders">
              <FontAwesomeIcon icon={faClipboardList} size="3x" />
              <h3>Show Orders</h3>
            </Link>
          </div>
          <div className="card">
            <Link to="/sales/clients">
              <FontAwesomeIcon icon={faClipboardList} size="3x" />
              <h3>Clients</h3>
            </Link>
          </div>
          <div className="card">
            <Link to="/sales/manage-clients">
              <FontAwesomeIcon icon={faUsers} size="3x" />
              <h3>Manage Clients</h3>
            </Link>
          </div>
          <div className="card">
            <Link to="/sales/profile">
              <FontAwesomeIcon icon={faUser} size="3x" />
              <h3>Profile</h3>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesDashboard;
