import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db, storage } from '../../firebaseConfig';
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNotification } from '../../NotificationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faEdit, faTrash, faEye, faEyeSlash, faAddressBook, faUser, faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from '../Modal';
import Navbar from '../Navbar';
import '../../styles/AdminDashboard.css';
import '../../styles/AdminComponents.css';
import '../../styles/ManageProducts.css';

const ManageProducts = () => {
  const [products, setProducts] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productEditData, setProductEditData] = useState({
    name: '',
    price: '',
    weight: '',
    unit: 'kg',
    count: '',
    description: '',
    quantity: '',
    image: null,
    active: true,
    measurementType: 'weight',
  });
  const [newImage, setNewImage] = useState(null);
  const [filter, setFilter] = useState('active');
  const notify = useNotification();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      const productsCollection = collection(db, 'products');
      const productsSnapshot = await getDocs(productsCollection);
      setProducts(productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchProducts();
  }, []);

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, 'products', selectedProduct.id));
      setProducts(products.filter(product => product.id !== selectedProduct.id));
      setDeleteModalOpen(false);
      notify('Product deleted successfully!');
    } catch (error) {
      console.error('Error deleting product: ', error);
      notify('Error deleting product.');
    }
  };

  const handleEdit = (product) => {
    const [weight, unit] = product.weight ? product.weight.split(' ') : ['', 'kg'];
    setSelectedProduct(product);
    setProductEditData({
      name: product.name,
      price: product.price,
      weight: weight || '',
      unit: unit || 'kg',
      count: product.count || '',
      description: product.description,
      quantity: product.quantity,
      image: product.image,
      active: product.active,
      measurementType: product.weight ? 'weight' : 'count',
    });
    setNewImage(null);
    setEditModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductEditData({ ...productEditData, [name]: value });
  };

  const handleUnitChange = (e) => {
    setProductEditData({ ...productEditData, unit: e.target.value });
  };

  const handleMeasurementTypeChange = (e) => {
    setProductEditData({ ...productEditData, measurementType: e.target.value });
  };

  const handleFileChange = (e) => {
    setNewImage(e.target.files[0]);
  };

  const handleSave = async () => {
    try {
      let imageUrl = productEditData.image;
      if (newImage) {
        const storageRef = ref(storage, `products/${newImage.name}`);
        await uploadBytes(storageRef, newImage);
        imageUrl = await getDownloadURL(storageRef);
      }

      const updatedData = {
        ...productEditData,
        weight: productEditData.measurementType === 'weight' ? `${productEditData.weight} ${productEditData.unit}` : '',
        count: productEditData.measurementType === 'count' ? productEditData.count : '',
        image: imageUrl,
      };

      await updateDoc(doc(db, 'products', selectedProduct.id), updatedData);

      setProducts(products.map(product => 
        product.id === selectedProduct.id ? { ...product, ...updatedData } : product
      ));
      setEditModalOpen(false);
      notify('Product updated successfully!');
    } catch (error) {
      console.error('Error updating product: ', error);
      notify('Error updating product.');
    }
  };

  const toggleActive = async (product) => {
    try {
      await updateDoc(doc(db, 'products', product.id), {
        active: !product.active,
      });
      setProducts(products.map(p => 
        p.id === product.id ? { ...p, active: !p.active } : p
      ));
      notify(`Product ${product.active ? 'deactivated' : 'activated'} successfully!`);
    } catch (error) {
      console.error('Error activating/deactivating product: ', error);
      notify('Error activating/deactivating product.');
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const filteredProducts = products.filter(product => {
    if (filter === 'all') return true;
    if (filter === 'active') return product.active;
    if (filter === 'inactive') return !product.active;
    return true;
  });

  return (
    <div className={`admin-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
      <h2>Admin Panel</h2>
        <ul>
          <li><Link to="/admin-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/add-user"><FontAwesomeIcon icon={faUserPlus} /> Add User</Link></li>
          <li><Link to="/add-product"><FontAwesomeIcon icon={faBoxOpen} /> Add Product</Link></li>
          <li><Link to="/add-client"><FontAwesomeIcon icon={faUsers} /> Add Client</Link></li>
          <li><Link to="/manage-products"><FontAwesomeIcon icon={faClipboardList} /> Manage Products</Link></li>
          <li><Link to="/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/manage-users"><FontAwesomeIcon icon={faUsers} /> Manage Users</Link></li>
          <li><Link to="/manage-sales"><FontAwesomeIcon icon={faUsers} /> Sales Man</Link></li>
          <li><Link to="/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        <h1>Manage Products</h1>
        <div className="filter-buttons">
          <button className={filter === 'active' ? 'active' : ''} onClick={() => setFilter('active')}>
            Active Products
          </button>
          <button className={filter === 'inactive' ? 'active' : ''} onClick={() => setFilter('inactive')}>
            Inactive Products
          </button>
          <button className={filter === 'all' ? 'active' : ''} onClick={() => setFilter('all')}>
            All Products
          </button>
        </div>
        <div className="manage-products-container">
          {filteredProducts.map(product => (
            <div key={product.id} className={`product-card ${product.active ? '' : 'inactive-product'}`}>
              {product.image ? (
                <img src={product.image} alt={product.name} className="product-image" />
              ) : (
                <div className="placeholder-image">No Image</div>
              )}
              <div className="product-details">
                <h2>{product.name}</h2>
                <p><strong>Price:</strong> ₪{product.price}</p>
                <p><strong>{product.weight ? 'Weight:' : 'Count:'}</strong> {product.weight || product.count}</p>
                <p><strong>Description:</strong> {product.description}</p>
                <p><strong>Quantity:</strong> {product.quantity}</p>
                <p><strong>Active:</strong> {product.active ? 'Yes' : 'No'}</p>
              </div>
              <div className="product-actions">
                <button className="edit-button" onClick={() => handleEdit(product)}>
                  <FontAwesomeIcon icon={faEdit} /> Edit
                </button>
                <button className="delete-button" onClick={() => { setSelectedProduct(product); setDeleteModalOpen(true); }}>
                  <FontAwesomeIcon icon={faTrash} /> Delete
                </button>
                <button className="inactive-button" onClick={() => toggleActive(product)}>
                  <FontAwesomeIcon icon={product.active ? faEyeSlash : faEye} /> {product.active ? 'Deactivate' : 'Activate'}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Edit Modal */}
      {editModalOpen && (
        <Modal isOpen={editModalOpen} onClose={() => setEditModalOpen(false)}>
          <div className="modal-content">
            <button className="close-modal-button" onClick={() => setEditModalOpen(false)}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h2>Edit Product</h2>
            <div className="form-group">
              <label>Product Name:</label>
              <input
                type="text"
                name="name"
                value={productEditData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Price:</label>
              <input
                type="number"
                name="price"
                value={productEditData.price}
                onChange={handleChange}
                required
                step="0.01"
              />
            </div>
            <div className="form-group">
              <label>Measurement Type:</label>
              <select
                name="measurementType"
                value={productEditData.measurementType}
                onChange={handleMeasurementTypeChange}
                className="measurement-type-select"
              >
                <option value="weight">Weight</option>
                <option value="count">Count</option>
              </select>
            </div>
            {productEditData.measurementType === 'weight' ? (
              <div className="form-group">
                <label>Weight:</label>
                <div className="weight-input-container">
                  <input
                    type="text"
                    name="weight"
                    value={productEditData.weight}
                    onChange={handleChange}
                    required
                  />
                  <select
                    name="unit"
                    value={productEditData.unit}
                    onChange={handleUnitChange}
                    className="unit-select"
                  >
                    <option value="kg">kg</option>
                    <option value="g">g</option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="form-group">
                <label>Count:</label>
                <input
                  type="number"
                  name="count"
                  value={productEditData.count}
                  onChange={handleChange}
                  required
                  min="1"
                />
              </div>
            )}
            <div className="form-group">
              <label>Description:</label>
              <textarea
                name="description"
                value={productEditData.description}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="form-group">
              <label>Quantity:</label>
              <input
                type="number"
                name="quantity"
                value={productEditData.quantity}
                onChange={handleChange}
                required
                min="1"
              />
            </div>
            <div className="form-group">
              <label>Image:</label>
              <input
                type="file"
                name="image"
                onChange={handleFileChange}
              />
              {productEditData.image && !newImage && (
                <div className="image-preview">
                  <img
                    src={productEditData.image}
                    alt="Product Preview"
                    style={{ maxWidth: '100%', maxHeight: '150px', marginTop: '10px' }}
                  />
                </div>
              )}
            </div>
            <button onClick={handleSave}>Save</button>
          </div>
        </Modal>
      )}

      {/* Delete Modal */}
      {deleteModalOpen && (
        <Modal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
          <div className="modal-content">
            <button className="close-modal-button" onClick={() => setDeleteModalOpen(false)}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h2>Delete Product</h2>
            <p>Are you sure you want to delete this product?</p>
            <button onClick={handleDelete}>Yes, Delete</button>
            <button onClick={() => setDeleteModalOpen(false)}>Cancel</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ManageProducts;
