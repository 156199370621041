import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { db, storage } from '../../firebaseConfig'; // Make sure 'storage' is imported
import { collection, addDoc } from 'firebase/firestore';
import { useNotification } from '../../NotificationContext';
import { useFileUpload } from '../hooks/useFileUpload'; // Custom Hook
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faUser, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../Navbar';
import '../../styles/AdminDashboard.css';
import '../../styles/AdminComponents.css'; 
import '../../styles/AddProduct.css';

const AddProduct = () => {
  const [product, setProduct] = useState({
    name: '',
    price: '',
    weight: '',
    unit: 'kg',
    description: '',
    count: '',
    quantity: '',
    image: null,
    measurementType: 'weight',
  });

  const notify = useNotification();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const fileInputRef = useRef(null);

  const { uploadFile, uploading, error: uploadError } = useFileUpload(storage); // Use custom hook with 'storage'

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleMeasurementTypeChange = (e) => {
    setProduct({ ...product, measurementType: e.target.value });
  };

  const handleUnitChange = (e) => {
    setProduct({ ...product, unit: e.target.value });
  };

  const handleFileChange = (e) => {
    setProduct({ ...product, image: e.target.files[0] });
  };

  const validateForm = () => {
    if (!product.name) {
      return 'Product name is required.';
    }
    if (product.price <= 0) {
      return 'Price must be greater than zero.';
    }
    if (product.measurementType === 'weight' && !product.weight) {
      return 'Weight is required for products measured by weight.';
    }
    if (product.measurementType === 'count' && (!product.count || product.count <= 0)) {
      return 'Count must be greater than zero for products measured by count.';
    }
    if (product.image && product.image.size > 7000000) {
      return 'Image size should be less than 5MB.';
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationError = validateForm();
    if (validationError) {
      notify(validationError);
      return;
    }

    try {
      let imageUrl = '';
      if (product.image) {
        imageUrl = await uploadFile(product.image, `products/${product.image.name}`);
      }

      await addDoc(collection(db, 'products'), {
        name: product.name,
        price: parseFloat(product.price),
        weight: product.measurementType === 'weight' ? `${product.weight} ${product.unit}` : '',
        count: product.measurementType === 'count' ? parseInt(product.count) : '',
        quantity: product.quantity ? parseInt(product.quantity) : null,
        description: product.description,
        image: imageUrl,
        active: true,
      });

      setProduct({
        name: '',
        price: '',
        weight: '',
        unit: 'kg',
        description: '',
        count: '',
        quantity: '',
        image: null,
        measurementType: 'weight',
      });

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      notify('Product added successfully and is now active!');
    } catch (error) {
      console.error('Error adding product: ', error);
      notify('Error adding product.');
    }
  };

  return (
    <div className={`admin-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
        <h2>Admin Panel</h2>
        <ul>
          <li><Link to="/admin-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/add-user"><FontAwesomeIcon icon={faUserPlus} /> Add User</Link></li>
          <li><Link to="/add-product"><FontAwesomeIcon icon={faBoxOpen} /> Add Product</Link></li>
          <li><Link to="/add-client"><FontAwesomeIcon icon={faUsers} /> Add Client</Link></li>
          <li><Link to="/manage-products"><FontAwesomeIcon icon={faClipboardList} /> Manage Products</Link></li>
          <li><Link to="/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/manage-users"><FontAwesomeIcon icon={faUsers} /> Manage Users</Link></li>
          <li><Link to="/manage-sales"><FontAwesomeIcon icon={faUsers} /> Sales Man</Link></li>
          <li><Link to="/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        <h1>Add Product</h1>
        <div className="add-product-container">
          {uploadError && <div className="error-message">{uploadError}</div>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Product Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Product Name"
                value={product.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="price">Price:</label>
              <input
                type="number"
                id="price"
                name="price"
                placeholder="Price"
                value={product.price}
                onChange={handleChange}
                required
                step="0.01"
              />
            </div>
            <div className="form-group">
              <label htmlFor="measurementType">Measurement Type:</label>
              <select id="measurementType" name="measurementType" value={product.measurementType} onChange={handleMeasurementTypeChange} className="unit-select">
                <option value="weight">Weight</option>
                <option value="count">Count</option>
              </select>
            </div>
            {product.measurementType === 'weight' && (
              <div className="form-group">
                <label htmlFor="weight">Weight:</label>
                <div className="weight-input-container">
                  <input
                    type="text"
                    id="weight"
                    name="weight"
                    placeholder="Weight"
                    value={product.weight}
                    onChange={handleChange}
                    required
                  />
                  <select id="unit" name="unit" value={product.unit} onChange={handleUnitChange} className="unit-select">
                    <option value="kg">kg</option>
                    <option value="g">g</option>
                  </select>
                </div>
              </div>
            )}
            {product.measurementType === 'count' && (
              <div className="form-group">
                <label htmlFor="count">Count:</label>
                <input
                  type="number"
                  id="count"
                  name="count"
                  placeholder="Count"
                  value={product.count}
                  onChange={handleChange}
                  required
                  min="1"
                />
              </div>
            )}
            <div className="form-group">
              <label htmlFor="quantity">Stock Quantity (Optional):</label>
              <input
                type="number"
                id="quantity"
                name="quantity"
                placeholder="Stock Quantity"
                value={product.quantity}
                onChange={handleChange}
                min="0"
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description (Optional):</label>
              <textarea
                id="description"
                name="description"
                placeholder="Description"
                value={product.description}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="form-group">
              <label htmlFor="image">Image:</label>
              <input
                type="file"
                id="image"
                name="image"
                onChange={handleFileChange}
                ref={fileInputRef}
                aria-describedby="image-description"
              />
              <p id="image-description">Upload a product image. Max size: 5MB.</p>
              {product.image && (
                <div className="image-preview">
                  <img
                    src={URL.createObjectURL(product.image)}
                    alt="Product Preview"
                    style={{ maxWidth: '100%', maxHeight: '150px', marginTop: '10px' }}
                  />
                </div>
              )}
            </div>
            <button type="submit" disabled={uploading || !product.name || !product.price}>
              {uploading ? 'Adding Product...' : 'Add Product'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
