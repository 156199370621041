import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { useNotification } from '../../NotificationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faUser, faEdit, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import Modal from '../Modal';
import Navbar from '../Navbar';
import '../../styles/SalesDashboard.css';
import '../../styles/AdminComponents.css';
import '../../styles/ManageClients.css';

const ManageClients = () => {
  const [clients, setClients] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientEditData, setClientEditData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: ''
  });
  const notify = useNotification();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    const fetchClients = async () => {
      const clientsCollection = collection(db, 'clients');
      const clientsSnapshot = await getDocs(clientsCollection);
      setClients(clientsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchClients();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'clients', id));
      setClients(clients.filter(client => client.id !== id));
      notify('Client deleted successfully!');
    } catch (error) {
      console.error('Error deleting client: ', error);
      notify('Error deleting client.');
    }
  };

  const handleEdit = (client) => {
    setSelectedClient(client);
    setClientEditData({
      firstName: client.firstName,
      lastName: client.lastName,
      email: client.email,
      phoneNumber: client.phoneNumber,
      address: client.address
    });
    setEditModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientEditData({ ...clientEditData, [name]: value });
  };

  const handleSave = async () => {
    try {
      const clientRef = doc(db, 'clients', selectedClient.id);
      const userRef = doc(db, 'users', selectedClient.uid);
      await updateDoc(clientRef, clientEditData);
      await updateDoc(userRef, clientEditData);
      setClients(clients.map(client => 
        client.id === selectedClient.id ? { ...client, ...clientEditData } : client
      ));
      setEditModalOpen(false);
      notify('Client updated successfully!');
    } catch (error) {
      console.error('Error updating client: ', error);
      notify('Error updating client.');
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={`sales-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
        <h2>Sales Panel</h2>
        <ul>
          <li><Link to="/sales-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/sales/add-client"><FontAwesomeIcon icon={faUserPlus} /> Add Client</Link></li>
          <li><Link to="/sales/add-order"><FontAwesomeIcon icon={faBoxOpen} /> Add Order</Link></li>
          <li><Link to="/sales/show-orders"><FontAwesomeIcon icon={faClipboardList} /> Show Orders</Link></li>
          <li><Link to="/sales/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/sales/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/sales/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        {/* <button className="toggle-sidebar" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faBars} />
        </button> */}
        <h1>Manage Clients</h1>
        <div className="manage-clients-container">
          {clients.map(client => (
            <div key={client.id} className="client-card">
              <div className="client-details">
                <h2>{client.firstName} {client.lastName}</h2>
                <p><strong>Email:</strong> {client.email}</p>
                <p><strong>Phone Number:</strong> {client.phoneNumber}</p>
                <p><strong>Address:</strong> {client.address}</p>
              </div>
              <div className="client-actions">
                <button className="edit-button" onClick={() => handleEdit(client)}><FontAwesomeIcon icon={faEdit} /> Edit</button>
                <button className="delete-button" onClick={() => handleDelete(client.id)}>Delete</button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {editModalOpen && (
        <Modal isOpen={editModalOpen} onClose={() => setEditModalOpen(false)}>
          <h2>Edit Client</h2>
          <div className="form-group">
            <label>First Name:</label>
            <input
              type="text"
              name="firstName"
              value={clientEditData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Last Name:</label>
            <input
              type="text"
              name="lastName"
              value={clientEditData.lastName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={clientEditData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Phone Number:</label>
            <input
              type="text"
              name="phoneNumber"
              value={clientEditData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Address:</label>
            <input
              type="text"
              name="address"
              value={clientEditData.address}
              onChange={handleChange}
              required
            />
          </div>
          <button onClick={handleSave}>Save</button>
        </Modal>
      )}
    </div>
  );
};

export default ManageClients;
