import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { useNotification } from '../../NotificationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faUser, faEdit, faTrash, faEye, faEyeSlash, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import Modal from '../Modal';
import Navbar from '../Navbar';
import '../../styles/AdminDashboard.css';
import '../../styles/AdminComponents.css';
import '../../styles/ManageClients.css';

const ManageClients = () => {
  const [clients, setClients] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientEditData, setClientEditData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    active: true, // Ensure active is set to true by default
  });
  const [filter, setFilter] = useState('active');
  const notify = useNotification();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    const fetchClients = async () => {
      const clientsCollection = collection(db, 'clients');
      const clientsSnapshot = await getDocs(clientsCollection);
      setClients(clientsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchClients();
  }, []);

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, 'clients', selectedClient.id));
      setClients(clients.filter(client => client.id !== selectedClient.id));
      setDeleteModalOpen(false);
      notify('Client deleted successfully!');
    } catch (error) {
      console.error('Error deleting client: ', error);
      notify('Error deleting client.');
    }
  };

  const handleEdit = (client) => {
    setSelectedClient(client);
    setClientEditData({
      firstName: client.firstName,
      lastName: client.lastName,
      email: client.email,
      phoneNumber: client.phoneNumber,
      address: client.address,
      active: client.active,
    });
    setEditModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientEditData({ ...clientEditData, [name]: value });
  };

  const handleSave = async () => {
    try {
      const clientRef = doc(db, 'clients', selectedClient.id);
      const userRef = doc(db, 'users', selectedClient.uid);
      await updateDoc(clientRef, clientEditData);
      await updateDoc(userRef, clientEditData);
      setClients(clients.map(client => 
        client.id === selectedClient.id ? { ...client, ...clientEditData } : client
      ));
      setEditModalOpen(false);
      notify('Client updated successfully!');
    } catch (error) {
      console.error('Error updating client: ', error);
      notify('Error updating client.');
    }
  };

  const toggleActive = async (client) => {
    try {
      await updateDoc(doc(db, 'clients', client.id), {
        active: !client.active,
      });
      setClients(clients.map(c => 
        c.id === client.id ? { ...c, active: !c.active } : c
      ));
      notify(`Client ${client.active ? 'deactivated' : 'activated'} successfully!`);
    } catch (error) {
      console.error('Error activating/deactivating client: ', error);
      notify('Error activating/deactivating client.');
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const filteredClients = clients.filter(client => {
    if (filter === 'all') return true;
    if (filter === 'active') return client.active;
    if (filter === 'inactive') return !client.active;
    return true;
  });

  return (
    <div className={`admin-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
        <h2>Admin Panel</h2>
        <ul>
          <li><Link to="/admin-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/add-user"><FontAwesomeIcon icon={faUserPlus} /> Add User</Link></li>
          <li><Link to="/add-product"><FontAwesomeIcon icon={faBoxOpen} /> Add Product</Link></li>
          <li><Link to="/add-client"><FontAwesomeIcon icon={faUsers} /> Add Client</Link></li>
          <li><Link to="/manage-products"><FontAwesomeIcon icon={faClipboardList} /> Manage Products</Link></li>
          <li><Link to="/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/manage-users"><FontAwesomeIcon icon={faUsers} /> Manage Users</Link></li>
          <li><Link to="/manage-sales"><FontAwesomeIcon icon={faUsers} /> Sales Man</Link></li>
          <li><Link to="/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        <h1>Manage Clients</h1>
        <div className="filter-buttons">
          <button 
            className={filter === 'active' ? 'active' : ''} 
            onClick={() => setFilter('active')}
          >
            Active Clients
          </button>
          <button 
            className={filter === 'inactive' ? 'active' : ''} 
            onClick={() => setFilter('inactive')}
          >
            Inactive Clients
          </button>
          <button 
            className={filter === 'all' ? 'active' : ''} 
            onClick={() => setFilter('all')}
          >
            All Clients
          </button>
        </div>
        <div className="manage-clients-container">
          {filteredClients.map(client => (
            <div key={client.id} className={`client-card ${client.active ? '' : 'inactive-client'}`}>
              <div className="client-details">
                <h2>{client.firstName} {client.lastName}</h2>
                <p><strong>Email:</strong> {client.email}</p>
                <p><strong>Phone Number:</strong> {client.phoneNumber}</p>
                <p><strong>Address:</strong> {client.address}</p>
                <p><strong>Active:</strong> {client.active ? 'Yes' : 'No'}</p>
              </div>
              <div className="client-actions">
                <button className="edit-button" onClick={() => handleEdit(client)}><FontAwesomeIcon icon={faEdit} /> Edit</button>
                <button className="delete-button" onClick={() => { setSelectedClient(client); setDeleteModalOpen(true); }}><FontAwesomeIcon icon={faTrash} /> Delete</button>
                <button className="inactive-button" onClick={() => toggleActive(client)}>
                  <FontAwesomeIcon icon={client.active ? faEyeSlash : faEye} /> {client.active ? 'Deactivate' : 'Activate'}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {editModalOpen && (
        <Modal isOpen={editModalOpen} onClose={() => setEditModalOpen(false)}>
          <h2>Edit Client</h2>
          <div className="form-group">
            <label>First Name:</label>
            <input
              type="text"
              name="firstName"
              value={clientEditData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Last Name:</label>
            <input
              type="text"
              name="lastName"
              value={clientEditData.lastName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={clientEditData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Phone Number:</label>
            <input
              type="text"
              name="phoneNumber"
              value={clientEditData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Address:</label>
            <input
              type="text"
              name="address"
              value={clientEditData.address}
              onChange={handleChange}
              required
            />
          </div>
          <button onClick={handleSave}>Save</button>
        </Modal>
      )}
      {deleteModalOpen && (
        <Modal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
          <h2>Delete Client</h2>
          <p>Are you sure you want to delete this client?</p>
          <button onClick={handleDelete}>Yes, Delete</button>
          <button onClick={() => setDeleteModalOpen(false)}>Cancel</button>
        </Modal>
      )}
    </div>
  );
};

export default ManageClients;
