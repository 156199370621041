// import React, { useContext } from 'react';
// import { Navigate } from 'react-router-dom';
// import { AuthContext } from '../AuthContext';

// const ProtectedRoute = ({ children, requiredRole }) => {
//   const { currentUser, userRole } = useContext(AuthContext);

//   if (!currentUser) {
//     return <Navigate to="/login" />;
//   }

//   if (requiredRole && userRole !== requiredRole) {
//     return <Navigate to="/not-authorized" />;
//   }

//   return children;
// };

// export default ProtectedRoute;


// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const ProtectedRoute = ({ requiredRole, children }) => {
  const { currentUser, userRole } = useAuth();

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (requiredRole && userRole !== requiredRole && userRole !== 'admin') {
    return <Navigate to="/not-authorized" />;
  }

  return children;
};

export default ProtectedRoute;
