import { useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export const useFileUpload = (storage) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const uploadFile = async (file, path) => {
    setUploading(true);
    setError(null);
    try {
      const storageRef = ref(storage, path);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      setFileUrl(url);
      return url;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setUploading(false);
    }
  };

  return { uploadFile, uploading, error, fileUrl };
};
