import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { useNotification } from '../../NotificationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faUser, faAddressBook, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../Navbar';
import Modal from '../Modal';
import '../../styles/AdminDashboard.css';
import '../../styles/AdminComponents.css';
import '../../styles/ManageStock.css';

const ManageStock = () => {
  const [products, setProducts] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantityInput, setQuantityInput] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const notify = useNotification();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, 'products');
        const productsSnapshot = await getDocs(productsCollection);
        const productsList = productsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setProducts(productsList);
      } catch (error) {
        console.error('Error fetching products: ', error);
        notify('Error fetching products.');
      }
    };

    fetchProducts();
  }, [notify]);

  const handleOpenModal = (product, type) => {
    setSelectedProduct(product);
    setQuantityInput(type === 'update' ? product.quantity : '');
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setQuantityInput('');
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^[0-9\b]+$/.test(value)) {
      setQuantityInput(value);
    }
  };

  const handleSaveQuantity = async (type) => {
    if (quantityInput === '' || parseInt(quantityInput, 10) < 0) {
      notify('Please enter a valid quantity.');
      return;
    }

    setIsUpdating(true);

    try {
      let updatedQuantity;

      if (type === 'add') {
        updatedQuantity = parseInt(selectedProduct.quantity, 10) + parseInt(quantityInput, 10);
      } else if (type === 'update') {
        updatedQuantity = parseInt(quantityInput, 10);
      }

      // Optimistic UI update
      setProducts(products.map(product =>
        product.id === selectedProduct.id ? { ...product, quantity: updatedQuantity } : product
      ));

      await updateDoc(doc(db, 'products', selectedProduct.id), {
        quantity: updatedQuantity
      });

      notify(`Quantity ${type === 'add' ? 'added' : 'updated'} successfully!`);
      handleCloseModal();
    } catch (error) {
      console.error(`Error ${type === 'add' ? 'adding' : 'updating'} quantity: `, error);
      notify(`Error ${type === 'add' ? 'adding' : 'updating'} quantity.`);
    } finally {
      setIsUpdating(false);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={`admin-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
        <h2>Admin Panel</h2>
        <ul>
          <li><Link to="/admin-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/add-user"><FontAwesomeIcon icon={faUserPlus} /> Add User</Link></li>
          <li><Link to="/add-product"><FontAwesomeIcon icon={faBoxOpen} /> Add Product</Link></li>
          <li><Link to="/manage-products"><FontAwesomeIcon icon={faClipboardList} /> Manage Products</Link></li>
          <li><Link to="/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/manage-users"><FontAwesomeIcon icon={faUsers} /> Manage Users</Link></li>
          <li><Link to="/manage-sales"><FontAwesomeIcon icon={faUsers} /> Sales Man</Link></li>
          <li><Link to="/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/manage-stock"><FontAwesomeIcon icon={faWarehouse} /> Manage Stock</Link></li>
          <li><Link to="/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        <h1>Manage Stock</h1>
        <div className="manage-stock-container">
          <table className="stock-table">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Current Stock</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {products.map(product => (
                <tr key={product.id}>
                  <td>{product.name}</td>
                  <td>{product.quantity}</td>
                  <td>
                    <button onClick={() => handleOpenModal(product, 'add')} className="add-quantity-button">
                      Add Quantity
                    </button>
                    <button onClick={() => handleOpenModal(product, 'update')} className="update-quantity-button">
                      Update Quantity
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal for adding/updating quantity */}
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <h2>{selectedProduct ? `${quantityInput ? 'Update' : 'Add'} Quantity for ${selectedProduct.name}` : ''}</h2>
          <input
            type="number"
            value={quantityInput}
            onChange={handleQuantityChange}
            min="0"
            placeholder="Enter quantity"
          />
          <button onClick={() => handleSaveQuantity(quantityInput ? 'update' : 'add')} className="update-stock-button" disabled={isUpdating}>
            {isUpdating ? 'Saving...' : 'Save Quantity'}
          </button>
        </Modal>
      )}
    </div>
  );
};

export default ManageStock;
