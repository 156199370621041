import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, getDocs, query, where, deleteDoc, doc } from 'firebase/firestore';
import Navbar from '../Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faUser, faAddressBook, faTrash } from '@fortawesome/free-solid-svg-icons';
import '../../styles/SalesDashboard.css';
import '../../styles/ShowOrders.css';
import Modal from '../Modal';

const ShowOrders = () => {
  const [orders, setOrders] = useState([]);
  const [clients, setClients] = useState({});
  const [sales, setSales] = useState({});
  const [statusFilter, setStatusFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [customDate, setCustomDate] = useState({ from: '', to: '' });
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);

  useEffect(() => {
    fetchOrders();
    fetchClients();
    fetchSales();
  }, [statusFilter, dateFilter, customDate]);

  const fetchOrders = async () => {
    let ordersQuery = collection(db, 'orders');

    // Apply status filter if it exists
    if (statusFilter) {
      ordersQuery = query(ordersQuery, where('status', '==', statusFilter));
    }

    // Apply date filter if it exists
    if (dateFilter) {
      ordersQuery = applyDateFilter(ordersQuery);
    }

    const ordersSnapshot = await getDocs(ordersQuery);
    setOrders(ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const fetchClients = async () => {
    const clientsCollection = collection(db, 'clients');
    const clientsSnapshot = await getDocs(clientsCollection);
    const clientsData = {};
    clientsSnapshot.docs.forEach(doc => {
      clientsData[doc.id] = doc.data();
    });
    setClients(clientsData);
  };

  const fetchSales = async () => {
    const usersCollection = collection(db, 'users');
    const usersSnapshot = await getDocs(usersCollection);
    const usersData = {};
    usersSnapshot.docs.forEach(doc => {
      usersData[doc.id] = doc.data();
    });
    setSales(usersData);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleDateFilterChange = (e) => {
    setDateFilter(e.target.value);
  };

  const applyDateFilter = (ordersQuery) => {
    const now = new Date();
    let fromDate, toDate;

    switch (dateFilter) {
      case 'today':
        fromDate = new Date(now.setHours(0, 0, 0, 0));
        toDate = new Date(now.setHours(23, 59, 59, 999));
        break;
      case 'yesterday':
        fromDate = new Date(now.setDate(now.getDate() - 1));
        fromDate.setHours(0, 0, 0, 0);
        toDate = new Date(now.setDate(now.getDate()));
        toDate.setHours(23, 59, 59, 999);
        break;
      case 'last7days':
        fromDate = new Date(now.setDate(now.getDate() - 7));
        toDate = new Date();
        break;
      case 'last28days':
        fromDate = new Date(now.setDate(now.getDate() - 28));
        toDate = new Date();
        break;
      case 'custom':
        fromDate = new Date(customDate.from);
        toDate = new Date(customDate.to);
        break;
      default:
        fromDate = null;
        toDate = null;
    }

    if (fromDate && toDate) {
      return query(
        ordersQuery,
        where('date', '>=', fromDate),
        where('date', '<=', toDate)
      );
    }

    return ordersQuery;
  };

  const handleCustomDateChange = (e) => {
    const { name, value } = e.target;
    setCustomDate((prev) => ({ ...prev, [name]: value }));
  };

  const handleDeleteClick = (order) => {
    setOrderToDelete(order);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteDoc(doc(db, 'orders', orderToDelete.id));
      setOrders(orders.filter(order => order.id !== orderToDelete.id));
      setShowDeleteModal(false);
      setOrderToDelete(null);
    } catch (error) {
      console.error("Error deleting order: ", error);
    }
  };

  return (
    <div className={`sales-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
        <h2>Sales Panel</h2>
        <ul>
          <li><Link to="/sales-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/sales/add-client"><FontAwesomeIcon icon={faUserPlus} /> Add Client</Link></li>
          <li><Link to="/sales/add-order"><FontAwesomeIcon icon={faBoxOpen} /> Add Order</Link></li>
          <li><Link to="/sales/show-orders"><FontAwesomeIcon icon={faClipboardList} /> Show Orders</Link></li>
          <li><Link to="/sales/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/sales/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/sales/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        <h1>Show Orders</h1>
        <div className="filters">
          <div className="filter">
            <label>Status:</label>
            <select value={statusFilter} onChange={handleStatusFilterChange} className="select-filter">
              <option value="">All</option>
              <option value="pending">Pending</option>
              <option value="ready">Ready</option>
              <option value="canceled">Canceled</option>
              <option value="complete">Complete</option>
            </select>
          </div>
          <div className="filter">
            <label>Date:</label>
            <select value={dateFilter} onChange={handleDateFilterChange} className="select-filter">
              <option value="">All</option>
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="last7days">Last 7 Days</option>
              <option value="last28days">Last 28 Days</option>
              <option value="custom">Custom</option>
            </select>
          </div>
          {dateFilter === 'custom' && (
            <div className="custom-date-filter">
              <label>From:</label>
              <input type="date" name="from" value={customDate.from} onChange={handleCustomDateChange} className="date-input" />
              <label>To:</label>
              <input type="date" name="to" value={customDate.to} onChange={handleCustomDateChange} className="date-input" />
            </div>
          )}
        </div>
        <div className="orders-container">
          {orders.map(order => (
            <div key={order.id} className="order-card">
              <button onClick={() => handleDeleteClick(order)} className="delete-icon-order">
                <FontAwesomeIcon icon={faTrash} />
              </button>
              <Link to={`/sales/order-detail/${order.id}`} className="order-link">
                <h2>Order Number: {order.orderNumber}</h2>
                <p><strong>Client:</strong> {clients[order.clientId]?.firstName} {clients[order.clientId]?.lastName}</p>
                <p><strong>Sales:</strong> {sales[order.salesId]?.firstName} {sales[order.salesId]?.lastName}</p>
                <p><strong>Total:</strong> ₪{order.total.toFixed(2)}</p>
                <p><strong>Status:</strong> {order.status}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
      {showDeleteModal && (
        <Modal isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
          <h2>Confirm Delete</h2>
          <p>Are you sure you want to delete this order?</p>
          <div className="modal-buttons">
            <button onClick={confirmDelete} className="confirm-button">Yes, Delete</button>
            <button onClick={() => setShowDeleteModal(false)} className="cancel-button">Cancel</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ShowOrders;
