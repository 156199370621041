// src/components/sales/Clients.js

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import Navbar from '../Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faUser, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import '../../styles/SalesDashboard.css';
import '../../styles/Clients.css';

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [orders, setOrders] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchClients = async () => {
      const clientsCollection = collection(db, 'clients');
      const clientsSnapshot = await getDocs(clientsCollection);
      setClients(clientsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    const fetchOrders = async () => {
      const ordersCollection = collection(db, 'orders');
      const ordersSnapshot = await getDocs(ordersCollection);
      setOrders(ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchClients();
    fetchOrders();
  }, []);

  const calculateTotalForClient = (clientId) => {
    const clientOrders = orders.filter(order => order.clientId === clientId);
    return clientOrders.reduce((total, order) => total + order.total, 0);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const filteredClients = clients.filter(client =>
    `${client.firstName} ${client.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className={`sales-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
        <h2>Sales Panel</h2>
        <ul>
          <li><Link to="/sales-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/sales/add-client"><FontAwesomeIcon icon={faUserPlus} /> Add Client</Link></li>
          <li><Link to="/sales/add-order"><FontAwesomeIcon icon={faBoxOpen} /> Add Order</Link></li>
          <li><Link to="/sales/show-orders"><FontAwesomeIcon icon={faClipboardList} /> Show Orders</Link></li>
          <li><Link to="/sales/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/sales/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/sales/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        <h1>Clients</h1>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search clients..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div className="clients-container">
          {filteredClients.map(client => (
            <Link to={`/sales/client-invoices/${client.id}`} key={client.id} className="client-card">
              <h2>{client.firstName} {client.lastName}</h2>
              <p><strong>Phone:</strong> {client.phoneNumber}</p>
              <p><strong>Address:</strong> {client.address}</p>
              <p><strong>Total:</strong> ₪{calculateTotalForClient(client.id).toFixed(2)}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Clients;
