import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import '../styles/ProductList.css';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(null); // State for handling errors

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsSnapshot = await getDocs(collection(db, 'products'));
        const productsList = productsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setProducts(productsList);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to load products');
      } finally {
        setLoading(false); // Stop loading after fetch
      }
    };

    fetchProducts();
  }, []);

  // Display loading state or error
  if (loading) {
    return <div className="full-page-wrapper"><h1>Loading Products...</h1></div>;
  }

  if (error) {
    return <div className="full-page-wrapper"><h1>{error}</h1></div>;
  }

  return (
    <div className="full-page-wrapper">
      <h1>Our Products</h1>
      <div className="products-grid">
        {products.length > 0 ? (
          products.map(product => (
            <div key={product.id} className="product-card">
              <img src={product.image} alt={product.name} className="product-image" />
              <h2>{product.name}</h2>
              <p>Price: ₪{parseFloat(product.price || 0).toFixed(2)}</p>

              {/* Conditional rendering for weight or count */}
              {product.weight ? (
                <p>Weight: {product.weight}</p>
              ) : (
                <p>Count: {product.count} pieces</p>
              )}
              
              <Link to={`/product-detail/${product.id}`} className="product-detail-link">View Details</Link>
            </div>
          ))
        ) : (
          <h2>No products available at the moment.</h2>
        )}
      </div>
    </div>
  );
};

export default ProductList;
