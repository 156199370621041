import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useNotification } from '../../NotificationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Navbar from '../Navbar';
import { faBars, faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faUser, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import '../../styles/SalesDashboard.css';
import '../../styles/AdminComponents.css';

const AddClient = () => {
  const [client, setClient] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    role: 'client',
    password: 'alforooj123',
    active: true, // Set client as active by default
  });

  const notify = useNotification();
  const functions = getFunctions();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      let email = value.replace(/@alfarooj.*/, ''); // Remove any existing domain
      email += '@alfarooj.ps'; // Append the fixed domain
      setClient({ ...client, email });
    } else {
      setClient({ ...client, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const createUser = httpsCallable(functions, 'createUser');
    try {
      const result = await createUser(client);
      if (result.data.success) {
        setClient({
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          address: '',
          role: 'client',
          password: 'alforooj123',
          active: true,
        });
        notify('Client added successfully!');
      } else {
        notify(`Error adding client: ${result.data.error}`);
      }
    } catch (error) {
      console.error('Error adding client: ', error);
      notify('Error adding client.');
    }
  };

  return (
    <div className={`admin-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
        <h2>Sales Panel</h2>
        <ul>
          <li><Link to="/sales-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/sales/add-client"><FontAwesomeIcon icon={faUserPlus} /> Add Client</Link></li>
          <li><Link to="/sales/add-order"><FontAwesomeIcon icon={faBoxOpen} /> Add Order</Link></li>
          <li><Link to="/sales/show-orders"><FontAwesomeIcon icon={faClipboardList} /> Show Orders</Link></li>
          <li><Link to="/sales/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/sales/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/sales/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        <h1>Add Client</h1>
        <div className="add-user-container">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>First Name:</label>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={client.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Last Name:</label>
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={client.lastName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input
                type="text"
                name="email"
                placeholder="Email"
                value={client.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Phone Number:</label>
              <input
                type="text"
                name="phoneNumber"
                placeholder="Phone Number"
                value={client.phoneNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Address:</label>
              <input
                type="text"
                name="address"
                placeholder="Address"
                value={client.address}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit">Add Client</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddClient;
