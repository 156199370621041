import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faUser, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../Navbar';
import '../../styles/AdminDashboard.css';

const AdminDashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={`admin-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
        <h2>Admin Panel</h2>
        <ul>
          <li><Link to="/admin-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/add-user"><FontAwesomeIcon icon={faUserPlus} /> Add User</Link></li>
          <li><Link to="/add-product"><FontAwesomeIcon icon={faBoxOpen} /> Add Product</Link></li>
          <li><Link to="/add-client"><FontAwesomeIcon icon={faUsers} /> Add Client</Link></li>
          <li><Link to="/manage-products"><FontAwesomeIcon icon={faClipboardList} /> Manage Products</Link></li>
          <li><Link to="/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/manage-users"><FontAwesomeIcon icon={faUsers} /> Manage Users</Link></li>
          <li><Link to="/manage-sales"><FontAwesomeIcon icon={faUsers} /> Sales Man</Link></li>
          <li><Link to="/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        <div className="card-grid">
          <div className="card">
            <Link to="/add-user">
              <FontAwesomeIcon icon={faUserPlus} size="3x" />
              <h3>Add User</h3>
            </Link>
          </div>
          <div className="card">
            <Link to="/add-product">
              <FontAwesomeIcon icon={faBoxOpen} size="3x" />
              <h3>Add Product</h3>
            </Link>
          </div>
          <div className="card">
            <Link to="/add-client">
              <FontAwesomeIcon icon={faUsers} size="3x" />
              <h3>Add Client</h3>
            </Link>
          </div>
          <div className="card">
            <Link to="/manage-products">
              <FontAwesomeIcon icon={faClipboardList} size="3x" />
              <h3>Manage Products</h3>
            </Link>
          </div>
          <div className="card">
            <Link to="/manage-clients">
              <FontAwesomeIcon icon={faUsers} size="3x" />
              <h3>Manage Clients</h3>
            </Link>
          </div>
          <div className="card">
            <Link to="/manage-users">
              <FontAwesomeIcon icon={faUsers} size="3x" />
              <h3>Manage Users</h3>
            </Link>
          </div>
          <div className="card">
            <Link to="/manage-stock">
              <FontAwesomeIcon icon={faUsers} size="3x" />
              <h3>Manage Stock</h3>
            </Link>
          </div>
          <div className="card">
            <Link to="/manage-sales">
              <FontAwesomeIcon icon={faUsers} size="3x" />
              <h3>Sales Man</h3>
            </Link>
          </div>
          <div className="card">
            <Link to="/clients">
              <FontAwesomeIcon icon={faUser} size="3x" />
              <h3>Clients</h3>
            </Link>
          </div>
          <div className="card">
            <Link to="/profile">
              <FontAwesomeIcon icon={faUser} size="3x" />
              <h3>Profile</h3>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
