import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import '../styles/ProductDetail.css';

const ProductDetail = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productDoc = await getDoc(doc(db, 'products', productId));
        if (productDoc.exists()) {
          setProduct(productDoc.data());
        } else {
          console.error('No such product!');
        }
      } catch (error) {
        console.error('Error fetching product: ', error);
      }
    };

    fetchProduct();
  }, [productId]);

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <div className="full-page-wrapper">
      <div className="product-detail-container">
        <img src={product.image} alt={product.name} className="product-detail-image" />
        <div className="product-detail-info">
          <h1>{product.name || 'Unknown Product'}</h1>
          <p className="product-detail-price">Price: ₪{parseFloat(product.price || 0).toFixed(2)}</p>

          {/* Conditional rendering for weight or count */}
          {product.weight ? (
            <p className="product-detail-weight">Weight: {product.weight}</p>
          ) : (
            <p className="product-detail-count">Count: {product.count} pieces</p>
          )}

          <p className="product-detail-description">
            {product.description || 'No description available.'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
