import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useNotification } from '../../NotificationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserPlus, faBoxOpen, faUsers, faClipboardList, faUserShield, faUser, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../Navbar';
import '../../styles/AdminDashboard.css';
import '../../styles/AdminComponents.css';

const AddUser = () => {
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    role: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const notify = useNotification();
  const functions = getFunctions();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const createUser = httpsCallable(functions, 'createUser');
    try {
      const result = await createUser(user);
      if (result.data.success) {
        setUser({
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          address: '',
          role: '',
          password: '',
        });
        notify('User added successfully!');
      } else {
        notify(`Error adding user: ${result.data.error}`);
      }
    } catch (error) {
      console.error('Error adding user: ', error);
      notify('Error adding user.');
    }
  };

  return (
    <div className={`admin-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="sidebar">
        <h2>Admin Panel</h2>
        <ul>
          <li><Link to="/admin-dashboard"><FontAwesomeIcon icon={faUserShield} /> Dashboard</Link></li>
          <li><Link to="/add-user"><FontAwesomeIcon icon={faUserPlus} /> Add User</Link></li>
          <li><Link to="/add-product"><FontAwesomeIcon icon={faBoxOpen} /> Add Product</Link></li>
          <li><Link to="/add-client"><FontAwesomeIcon icon={faUsers} /> Add Client</Link></li>
          <li><Link to="/manage-products"><FontAwesomeIcon icon={faClipboardList} /> Manage Products</Link></li>
          <li><Link to="/manage-clients"><FontAwesomeIcon icon={faUsers} /> Manage Clients</Link></li>
          <li><Link to="/manage-users"><FontAwesomeIcon icon={faUsers} /> Manage Users</Link></li>
          <li><Link to="/manage-sales"><FontAwesomeIcon icon={faUsers} /> Sales Man</Link></li>
          <li><Link to="/clients"><FontAwesomeIcon icon={faAddressBook} /> Clients</Link></li>
          <li><Link to="/profile"><FontAwesomeIcon icon={faUser} /> Profile</Link></li>
        </ul>
      </div>
      <div className="dashboard-content">
        <h1>Add User</h1>
        <div className="add-user-container">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>First Name:</label>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={user.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Last Name:</label>
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={user.lastName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={user.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Phone Number:</label>
              <input
                type="text"
                name="phoneNumber"
                placeholder="Phone Number"
                value={user.phoneNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Address:</label>
              <input
                type="text"
                name="address"
                placeholder="Address"
                value={user.address}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Role:</label>
              <select
                name="role"
                value={user.role}
                onChange={handleChange}
                required
              >
                <option value="">Select Role</option>
                <option value="admin">Admin</option>
                <option value="sales">Sales</option>
                <option value="client">Client</option>
              </select>
            </div>
            <div className="form-group">
              <label>Password:</label>
              <div className="password-container">
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Password"
                  value={user.password}
                  onChange={handleChange}
                  required
                />
                <button
                  type="button"
                  className="show-password-toggle"
                  onClick={() => setShowPassword(prevState => !prevState)}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </button>
              </div>
            </div>
            <button type="submit">Add User</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
