import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, getDocs, query, where, getDoc, doc } from 'firebase/firestore';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import '../../styles/DetailedStatement.css';
import '../../styles/DetailedStatementPrint.css';  // Import print-specific CSS
import logo from '../../images/logo.png'

const DetailedStatement = () => {
  const { clientId } = useParams();
  const [invoices, setInvoices] = useState([]);
  const [client, setClient] = useState(null);
  const [statement, setStatement] = useState({ totalInvoiced: 0, totalPaid: 0, totalDiscount: 0, balanceDue: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const statementRef = useRef();

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const invoicesQuery = query(collection(db, 'orders'), where('clientId', '==', clientId));
        const invoicesSnapshot = await getDocs(invoicesQuery);
        const fetchedInvoices = invoicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        let totalInvoiced = 0;
        let totalPaid = 0;
        let totalDiscount = 0;

        for (const invoice of fetchedInvoices) {
          const subtotal = parseFloat(invoice.subtotal) || 0;
          const discount = parseFloat(invoice.discountAmount) || 0;
          const invoiceTotal = subtotal - discount;
          totalInvoiced += subtotal;
          totalDiscount += discount;

          const paymentsCollection = collection(db, `orders/${invoice.id}/payments`);
          const paymentsSnapshot = await getDocs(paymentsCollection);
          const invoicePayments = paymentsSnapshot.docs.map(paymentDoc => paymentDoc.data());

          const invoicePaid = invoicePayments.reduce((sum, payment) => sum + (parseFloat(payment.amount) || 0), 0);
          totalPaid += invoicePaid;

          invoice.payments = invoicePayments;
          invoice.totalPaid = invoicePaid;
        }

        const balanceDue = totalInvoiced - totalDiscount - totalPaid;

        setInvoices(fetchedInvoices);
        setStatement({ totalInvoiced, totalPaid, totalDiscount, balanceDue });
      } catch (error) {
        console.error('Error fetching invoices:', error);
        setError('Failed to fetch invoices. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    const fetchClient = async () => {
      try {
        const clientDoc = await getDoc(doc(db, 'clients', clientId));
        if (clientDoc.exists()) {
          setClient(clientDoc.data());
        } else {
          setError('Client not found.');
        }
      } catch (error) {
        console.error('Error fetching client:', error);
        setError('Failed to fetch client details. Please try again later.');
      }
    };

    fetchInvoices();
    fetchClient();
  }, [clientId]);

  const handleDownloadPDF = async () => {
    const element = statementRef.current;
    const canvas = await html2canvas(element, { scale: 2 });
    const imageData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgProps = pdf.getImageProperties(imageData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
    pdf.addImage(imageData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('client-statement.pdf');
  };

  const handlePrint = () => {
    window.print();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!client) {
    return <div>Client not found</div>;
  }

  return (
    <div className="detailed-statement" ref={statementRef}>
      <h2>Detailed Client Statement</h2>
      <div className="statement-header">
        <img src= {logo} alt="Company Logo" className="company-logo" />
        <div className="company-details">
          <p>شركة الفروج التجارية الزراعية </p>
          <p>الخليل، فلسطين</p>
          <p>+972569736666</p>
          <p>dweikbashar9@gmail.com</p>
        </div>
      </div>

      <div className="statement-title">
        <h3>Statement of Account</h3>
        {/* <p>{new Date().toLocaleDateString()} - {new Date().toLocaleDateString()}</p> */}
      </div>

      <div className="account-summary">
        <h3>Account Summary</h3>

        <div className="summary-row">
          <span>Invoice Amount:</span> <span>₪{statement.totalInvoiced.toFixed(2)}</span>
        </div>
        <div className="summary-row">
          <span>Total Discount:</span> <span>₪{statement.totalDiscount.toFixed(2)}</span>
        </div>
        <div className="summary-row">
          <span>Amount Paid:</span> <span>₪{statement.totalPaid.toFixed(2)}</span>
        </div>
        <div className="summary-row">
          <span>Closing Balance:</span> <span>₪{statement.balanceDue.toFixed(2)}</span>
        </div>
      </div>

      <div className="transactions-table">
        <table>
          <thead>
            <tr>
              <th>Invoice Number</th>
              <th>Item</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map(invoice => (
              <>
                {invoice.items && invoice.items.map((item, index) => (
                  <tr key={`${invoice.id}-item-${index}`}>
                    <td>{invoice.orderNumber}</td>
                    <td>{item.name}</td>
                    <td>{item.quantity}</td>
                    <td>₪{parseFloat(item.price).toFixed(2)}</td>
                    <td>₪{(parseFloat(item.price) * item.quantity).toFixed(2)}</td>
                    <td>{invoice.date && invoice.date.seconds ? new Date(invoice.date.seconds * 1000).toLocaleDateString() : 'Date not available'}</td>
                  </tr>
                ))}
                {invoice.payments && invoice.payments.map((payment, index) => (
                  <tr key={`${invoice.id}-payment-${index}`}>
                    <td colSpan="4">Payment</td>
                    <td>₪{payment.amount.toFixed(2)}</td>
                    <td>{payment.timestamp && payment.timestamp.seconds ? new Date(payment.timestamp.seconds * 1000).toLocaleDateString() : 'Date not available'}</td>
                  </tr>
                ))}
              </>
            ))}
            <tr>
              <td colSpan="2"><strong>Total Invoiced</strong></td>
              <td colSpan="2"><strong>₪{statement.totalInvoiced.toFixed(2)}</strong></td>
              <td><strong>₪{statement.totalPaid.toFixed(2)}</strong></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="2"><strong>Total Discount</strong></td>
              <td colSpan="2"><strong>₪{statement.totalDiscount.toFixed(2)}</strong></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="2"><strong>Total Payments</strong></td>
              <td colSpan="2"><strong>₪{statement.totalPaid.toFixed(2)}</strong></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="2"><strong>Closing Balance</strong></td>
              <td colSpan="2"><strong>₪{statement.balanceDue.toFixed(2)}</strong></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="modal-buttons">
        <button onClick={handlePrint} className="print-button">Print</button>
        <button onClick={handleDownloadPDF} className="download-button">Download PDF</button>
        <button onClick={() => window.history.back()} className="close-button">Close</button>
      </div>
    </div>
  );
};

export default DetailedStatement;
